import EditIcon from "@mui/icons-material/EditOutlined";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import crmConnectorsApi, {
  CrmObjectSyncSettings,
  CrmSyncObjectKey,
  CrmSyncSettings,
  ObjectDefinitions,
} from "../../../../api/crmConnectorsApi";
import { useCrmConnectorContext } from "../CrmConectorContext";
import CrmFieldMappingEdit from "./CrmFieldMappingEdit";
import CrmObjectMapping from "./CrmObjectMapping";
import MappingValidationErrors from "./MappingValidationErrors";
import {
  FieldMapping,
  reducer as formReducer,
  getFieldsMapping,
  getInitialState as getInitialFormState,
} from "./fieldMappingsState";
import { getInitialState as getInitialValidationState, reducer as validationReducer } from "./validationState";

interface Props {
  objectKey: CrmSyncObjectKey;
  objectSyncSettings: CrmObjectSyncSettings;
  onCancel: () => void;
  ourObjects: ObjectDefinitions;
  crmObjects: ObjectDefinitions;
}

const FieldMappingDialog = ({ objectKey, objectSyncSettings, onCancel, ourObjects, crmObjects }: Props) => {
  const { crmName, crmApiName, syncSettings, onSyncSettingsUpdate } = useCrmConnectorContext();

  const [saving, setSaving] = useState(false);

  const [formState, dispatchForm] = useReducer(
    formReducer,
    getInitialFormState(objectSyncSettings, ourObjects, crmObjects)
  );

  const [validation, dispatchValidation] = useReducer(validationReducer, getInitialValidationState());
  const [isEditingFilter, setEditingFilter] = useState(false);

  const handleSave = async () => {
    if (syncSettings === undefined) {
      return;
    }

    setSaving(true);
    try {
      const newSyncSettings: CrmSyncSettings = {
        ...syncSettings,
        [objectKey]: {
          ...objectSyncSettings,
          fieldsMapping: getFieldsMapping(formState),
          accountTypeFilterValue: formState.crmFilterValue,
        },
      };

      const validateResp = await crmConnectorsApi[crmApiName].validateSyncSettings(newSyncSettings);
      dispatchValidation({ type: "VALIDATE", payload: validateResp });

      if (validateResp.data?.errors.length === 0) {
        const updateResp = await crmConnectorsApi[crmApiName].postSyncSettings(newSyncSettings);
        if (updateResp.success) {
          onSyncSettingsUpdate?.(updateResp.data);
        }
      }
    } finally {
      setSaving(false);
      setEditingFilter(false);
    }
  };

  const handleFieldMappingChange = (fieldMapping: FieldMapping) =>
    dispatchForm({ type: "UPDATE", payload: fieldMapping });

  const handleCancel = () => onCancel();

  const handleErrorsClose = () => dispatchValidation({ type: "HIDE_ERRORS" });

  const handleChangeFilterClick = () => setEditingFilter(true);

  const handleFilterValueChange = (filterValue: string) =>
    dispatchForm({ type: "UPDATE_CRM_FILTER", payload: filterValue });

  const crmFieldsObject = crmObjects[objectSyncSettings.crmObjectName]?.fields;

  const crmFields =
    crmFieldsObject === undefined ? [] : Object.entries(crmFieldsObject).map(([id, { name }]) => ({ id, name }));

  const isFilterEditable = !!objectSyncSettings.accountTypeFilterField && !!crmFieldsObject;

  return (
    <Dialog open onClose={handleCancel} sx={{ ".MuiPaper-root": { maxWidth: "100%" } }}>
      <DialogTitle>Field Mapping</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <Divider />
      <DialogContent sx={(theme) => ({ px: theme.spacing(4) })}>
        <Stack direction="row" spacing={36} mt={3} mb={2}>
          <Typography variant="subtitle2">Entrilia</Typography>
          <Typography variant="subtitle2">{crmName}</Typography>
        </Stack>
        <CrmObjectMapping
          objectSyncSettings={objectSyncSettings}
          crmFieldsObject={crmFieldsObject}
          crmFilterValue={formState.crmFilterValue}
          isEditing={isEditingFilter}
          onFilterValueChange={handleFilterValueChange}
        />
        <Stack direction="row" justifyContent="flex-end" mt={1}>
          {isFilterEditable && (
            <Button
              variant="text"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleChangeFilterClick}
              disabled={isEditingFilter}
            >
              Change Filter
            </Button>
          )}
        </Stack>
        <MappingValidationErrors errorMessages={validation.errorMessages} onClose={handleErrorsClose} />
        <Divider sx={{ my: 2 }} />
        {formState.mappings.map((mapping) => (
          <CrmFieldMappingEdit
            key={mapping.entriliaFieldId}
            fieldMapping={mapping}
            crmFields={crmFields}
            hasError={validation.errorFields.includes(mapping.entriliaFieldId)}
            onChange={handleFieldMappingChange}
            containerProps={{ mb: 1 }}
          />
        ))}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="primary" autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton variant="contained" color="primary" loading={saving} onClick={handleSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FieldMappingDialog;
