import { TextField, TextFieldProps } from "@mui/material";
import { FocusEvent, KeyboardEvent, useCallback, useState } from "react";
import { formatNumber, getLocalNumberFormatConfig } from "../utilities/formatters";
import { convertToNumber } from "../utilities/numberHelper";
import CloseIconButton from "./CloseIconButton";

type Props = TextFieldProps & {
  numberValue: number | undefined;
  onNumberChange: (number: number | undefined) => void;
  valueFormatter?: (value: number) => string;
  clearable?: boolean;
  selectOnFocus?: boolean;
};

const numberFormatConfig = getLocalNumberFormatConfig();

interface State {
  textValue: string;
  focused: boolean;
}

const NumberTextField = ({
  numberValue,
  onNumberChange,
  valueFormatter,
  clearable,
  selectOnFocus,
  ...props
}: Props) => {
  const formatValue = useCallback(
    (value: number | undefined) =>
      value === undefined ? "" : valueFormatter ? valueFormatter(value) : formatNumber(value),
    [valueFormatter]
  );

  const [state, setState] = useState<State>({ textValue: formatValue(numberValue), focused: false });

  const handleChange = (textValue: string) => {
    setState((prev) => ({ ...prev, textValue }));

    const newNumber = convertToNumber(textValue.replaceAll(numberFormatConfig.decimalSeparator, "."));
    if (newNumber !== undefined || textValue.trim() === "") {
      onNumberChange(newNumber);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.length === 1 && e.key !== numberFormatConfig.decimalSeparator && !/[-\d]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleBlur = () => {
    setState({ textValue: formatValue(numberValue), focused: false });
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({
      textValue: numberValue?.toString().replaceAll(".", numberFormatConfig.decimalSeparator) ?? "",
      focused: true,
    });

    if (selectOnFocus) {
      setTimeout(() => e.target.select());
    }
  };

  const { slotProps, ...textFieldProps } = props;

  return (
    <TextField
      {...textFieldProps}
      value={state.focused ? state.textValue : formatValue(numberValue)}
      onChange={(e) => handleChange(e.target.value)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
      slotProps={{
        ...slotProps,
        htmlInput: { inputMode: "numeric" },
        input: {
          endAdornment: clearable && !!state.textValue && (
            <CloseIconButton tabIndex={-1} onClick={() => handleChange("")} sx={{ p: 0 }} />
          ),
        },
      }}
    />
  );
};

export default NumberTextField;
