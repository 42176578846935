import ImportDataIcon from "@mui/icons-material/ExitToAppRounded";
import { Avatar } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ExcelIcon from "../../../../shared/icons/fileFormats/ExcelIcon";
import PassthroughLogo from "../../../images/passthrough.png";
import { pageRoutes } from "../../../routes";
import ActionsMenuButton, { ActionMenuItemProps } from "../../common/ActionsMenuButton";
import PassthroughDataProviderDialog from "./providers/passthrough/PassthroughDataProviderDialog";

interface Props {
  hasAccessToCsvImport: boolean;
  hasAccessToPassthroughImport: boolean;
}

const ImportDataPageActions = ({ hasAccessToCsvImport, hasAccessToPassthroughImport }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showPassthroughDialog, setShowPassthroughDialog] = useState(false);

  const handleImportFromCsvClick = () => {
    navigate(`${pageRoutes.importCsvData}`, { state: { referrerPath: pathname } });
  };

  const handleImportFromPassthroughClick = () => {
    setShowPassthroughDialog(true);
  };

  const items: ActionMenuItemProps[] = [];

  if (hasAccessToCsvImport) {
    items.push({
      label: "Import from CSV",
      onClick: handleImportFromCsvClick,
      icon: <ExcelIcon />,
    });
  }

  if (hasAccessToPassthroughImport) {
    items.push({
      label: "Import from Passthrough",
      onClick: handleImportFromPassthroughClick,
      icon: <Avatar src={PassthroughLogo} variant="square" sx={{ width: 20, height: 20 }} />,
    });
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <ActionsMenuButton text="Import Data" variant="contained" icon={<ImportDataIcon />} items={items} />
      {showPassthroughDialog && <PassthroughDataProviderDialog onClose={() => setShowPassthroughDialog(false)} />}
    </>
  );
};

export default ImportDataPageActions;
