import BugIcon from "@mui/icons-material/BugReportOutlined";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import { CircularProgress, MenuItem, Select, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridGroupingColDefOverride,
  GridRowGroupingModel,
} from "@mui/x-data-grid-premium";
import FileIcon from "../../../../../../../shared/components/FileIcon";
import DataGridGroupHeader from "../../../../../../../shared/components/grid/DataGridGroupHeader";
import { arraysHaveSameItems } from "../../../../../../../shared/utilities/arrayHelper";
import { convertISODateTime } from "../../../../../../../shared/utilities/dateUtils";
import { formatFileSize, getFileExtension } from "../../../../../../../shared/utilities/fileHelper";
import FundraisingCategorySelect from "./FundraisingCategorySelect";
import FundraisingDocumentActionsCell from "./FundraisingDocumentActionsCell";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import FundraisingDocumentSectionActionsCell from "./FundraisingDocumentSectionActionsCell";
import {
  FundraisingDocument,
  isChangeAccessOptionsAllowed,
  selectAccessOptions,
  updateDocumentsAccessOptionsAction,
  updateDocumentsCategoryAction,
} from "./fundraisingDocumentsState";

const FileStatusOrIcon = ({ row }: { row: FundraisingDocument }) => {
  if (row.isDownloading) {
    return <CircularProgress size={20} color="secondary" />;
  }

  if (row.uploadStatus === "uploading") {
    return <CircularProgress size={20} color="secondary" />;
  }

  if (row.uploadStatus === "upload_completed") {
    return <CheckIcon color="success" />;
  }

  if (row.uploadStatus === "error") {
    if (row.validationError) {
      return (
        <Tooltip title={row.validationError} arrow>
          <ErrorIcon color="error" />
        </Tooltip>
      );
    }

    if (row.uploadError?.type === "MalwareDetected") {
      return (
        <Tooltip title="Upload failed: malware detected" arrow>
          <BugIcon color="error" />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Upload failed" arrow>
        <ErrorIcon color="error" />
      </Tooltip>
    );
  }

  return <FileIcon fileExtension={getFileExtension(row.name)} />;
};

const FileNameCell = ({ row }: { row: FundraisingDocument }) => {
  const { onPreviewFile } = useFundraisingDocumentsContext();

  const handleClick = () => {
    if (row.uploadStatus === undefined) {
      onPreviewFile(row.fileId);
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%" pl={5}>
      <FileStatusOrIcon row={row} />
      <Typography
        noWrap
        onClick={handleClick}
        color={row.uploadStatus === undefined ? "inherit" : "text.secondary"}
        sx={(t) =>
          row.uploadStatus === undefined ? { cursor: "pointer", "&:hover": { color: t.palette.primary.main } } : {}
        }
      >
        {row.name}
      </Typography>
    </Stack>
  );
};

const CategorySelectCell = ({ row }: { row: FundraisingDocument }) => {
  const { documentsState, setDocumentsState } = useFundraisingDocumentsContext();

  const handleChange = (externalCategoryId: string | undefined) => {
    setDocumentsState(updateDocumentsCategoryAction([row.fileId], externalCategoryId));
  };

  return (
    <FundraisingCategorySelect
      variant="standard"
      externalCategoryId={row.externalCategoryId}
      onChange={handleChange}
      disabled={documentsState.isSaving}
    />
  );
};

const AccessOptionsSelect = ({ row }: { row: FundraisingDocument }) => {
  const { documentsState, setDocumentsState } = useFundraisingDocumentsContext();

  const handleChange = (e: SelectChangeEvent) => {
    const newOptions = selectAccessOptions.find(([value]) => value === e.target.value)?.[1];
    if (newOptions !== undefined) {
      setDocumentsState(updateDocumentsAccessOptionsAction([row.fileId], newOptions));
    }
  };

  const defaultValue = "View & Download";
  const defaultOptions = ["View", "Download"];
  const rowValue =
    selectAccessOptions.find(([, options]) => arraysHaveSameItems(options, row.accessOptions ?? defaultOptions))?.[0] ??
    defaultValue;

  return (
    <Select
      variant="standard"
      disableUnderline
      disabled={documentsState.isSaving}
      value={rowValue}
      onChange={handleChange}
      sx={{
        ".MuiInputBase-input": {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      {selectAccessOptions.map(([value]) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};

export const gridRowGroupingModel: GridRowGroupingModel = ["section"];

export const gridColumnVisibilityModel: GridColumnVisibilityModel = {
  name: false,
  section: false,
};

export const groupingColumnDefinition: GridGroupingColDefOverride<FundraisingDocument> = {
  leafField: "name",
  hideDescendantCount: true,
  renderHeader: (params) => <DataGridGroupHeader params={params} />,
  flex: 2,
};

export const columnDefinitions: GridColDef<FundraisingDocument>[] = [
  {
    field: "name",
    headerName: "Name",
    cellClassName: "highlighted-action",
    renderCell: ({ row, rowNode }) => (rowNode.type === "group" ? null : <FileNameCell row={row} />),
  },
  {
    field: "section",
    renderCell: ({ rowNode, colDef }) => {
      if (rowNode.type === "group") {
        return colDef.field === "section" ? null : <Typography variant="subtitle2">{rowNode.groupingKey}</Typography>;
      }

      return null;
    },
  },
  {
    field: "externalCategoryId",
    headerName: "Category",
    flex: 1,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return null;
      }

      return <CategorySelectCell row={row} />;
    },
  },
  {
    field: "accessOptions",
    headerName: "File Access",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row, rowNode }) =>
      rowNode.type === "group" ? null : isChangeAccessOptionsAllowed(row) ? (
        <AccessOptionsSelect row={row} />
      ) : (
        "Download"
      ),
  },
  {
    field: "size",
    headerName: "Size",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row, rowNode }) =>
      rowNode.type === "group" ? null : (
        <Typography color={row.uploadStatus === undefined ? "text.primary" : "text.secondary"}>
          {formatFileSize(row.size)}
        </Typography>
      ),
  },
  {
    field: "uploadedAt",
    headerName: "Uploaded at",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row, rowNode }) =>
      rowNode.type === "group" || !row.uploadedAt ? null : (
        <Typography color={row.uploadStatus === undefined ? "text.primary" : "text.secondary"}>
          {convertISODateTime(row.uploadedAt)}
        </Typography>
      ),
  },
  {
    field: "actions",
    headerName: "",
    width: 80,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row, rowNode }) =>
      rowNode.type === "group" ? (
        <FundraisingDocumentSectionActionsCell rowNode={rowNode} />
      ) : (
        <FundraisingDocumentActionsCell row={row} />
      ),
  },
];
