import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useReducer, useState } from "react";
import { DataCollectionSubmissionStatus } from "../../../../../shared/api/dataCollectionTypes";
import BadgeDetached from "../../../../../shared/components/BadgeDetached";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import storage from "../../../../storage/storage";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, filterRecords, getInitialState } from "../../../common/filters/filterState";
import DataSubmissionsGrid from "./DataSubmissionsGrid";
import {
  mySubmissionsFilterDefinitions,
  mySubmissionsSearchDefinition,
  selectOptionsResolver,
} from "./dataSubmissionsGridFilters";

const MyDataSubmissionsList = () => {
  const { clientCode } = useClientContext();

  const [statusFilterValue, setStatusFilterValue] = useState<DataCollectionSubmissionStatus>("Pending");

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DataCollectionSubmissionInfo>(),
    getInitialState(
      `${clientCode}_my_data_submissions_filter_v1`,
      mySubmissionsFilterDefinitions,
      mySubmissionsSearchDefinition,
      []
    )
  );

  const [dataSubmissionsResp, fetchError, { isFetching, fetch: fetchDataSubmissions }] = useFetch(
    adminApi.getAssignedDataSubmissions
  );

  if (fetchError) {
    logError(fetchError, "[MyDataSubmissionsList] fetch");
    storage.clearFilterState(`${clientCode}_my_data_submissions_filter_v1`);
    return <DataLoadingFailed title="Could not load data submissions" />;
  }

  const dataSubmissions = dataSubmissionsResp ?? [];

  const pendingSubmissions = dataSubmissions.filter((ds) => ds.status === "Pending");
  const completedSubmissions = dataSubmissions.filter((ds) => ds.status === "Completed");
  const allSelectedSubmissions = statusFilterValue === "Pending" ? pendingSubmissions : completedSubmissions;
  const filteredSubmissions = filterRecords(filterState, allSelectedSubmissions);

  return (
    <FilterContextProvider
      filterState={filterState}
      dispatchFilters={dispatchFilters}
      getSelectOptions={selectOptionsResolver}
      allRowsForSelectOptions={dataSubmissions}
    >
      <Stack height="100%" spacing={2}>
        <Stack direction="row" spacing={1}>
          <ToggleButtonGroup
            value={statusFilterValue}
            exclusive
            onChange={(_, value) => setStatusFilterValue(value)}
            sx={{ height: "2.25rem" }}
          >
            <ToggleButton value="Pending" sx={{ gap: 1 }}>
              Active <BadgeDetached badgeContent={pendingSubmissions.length} color="secondary" />
            </ToggleButton>
            <ToggleButton value="Completed" sx={{ gap: 1 }}>
              Completed <BadgeDetached badgeContent={completedSubmissions.length} color="secondary" />
            </ToggleButton>
          </ToggleButtonGroup>

          <FiltersPanel
            totalCount={allSelectedSubmissions.length}
            recordCount={filteredSubmissions.length}
            isLoading={isFetching}
            onRefresh={fetchDataSubmissions}
          />
        </Stack>

        <DataSubmissionsGrid rows={filteredSubmissions} isLoading={isFetching} />
      </Stack>
    </FilterContextProvider>
  );
};

export default MyDataSubmissionsList;
