import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Stack, SvgIconProps, SxProps, Theme, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { ComponentType, JSX, PropsWithChildren, useMemo } from "react";
import HorizontalFill from "../../../../../../../shared/components/HorizontalFill";
import FunctionIcon from "../../../../../../../shared/reporting/icons/FunctionIcon";
import HashIcon from "../../../../../../../shared/reporting/icons/HashIcon";
import NavigationParentIcon from "../../../../../../../shared/reporting/icons/NavigationParentIcon";
import { DimensionValidationResult, MeasuresValidationResult } from "../../../../../../api/biApi";

interface DimensionValidationProps {
  field: DimensionValidationResult;
  sx?: SxProps<Theme>;
}
export function DimensionValidation({ field, sx }: DimensionValidationProps) {
  return <ItemValidation field={field} Icon={HashIcon} containerSx={sx} subMessage={<ItemValid field={field} />} />;
}

export function MeasureValidation({ field }: { field: MeasuresValidationResult }) {
  const showBorder = useMemo(() => {
    return field.customConditions.length > 0 || !field.valid;
  }, [field.customConditions.length, field.valid]);

  return (
    <>
      <ItemValidation
        field={field}
        Icon={FunctionIcon}
        containerSx={{ borderColor: showBorder ? "#F1F1F1" : "transparent" }}
        itemSx={{ py: 0.5, borderBottom: field.customConditions.length > 0 ? "1px dashed #F1F1F1" : "none" }}
        subMessage={<ItemValid field={field} />}
      >
        {field.customConditions.map((cc) => (
          <ItemValidationComponent
            key={cc.name}
            field={cc}
            Icon={NavigationParentIcon}
            containerSx={{ py: 0.5 }}
            subMessage={<ItemValid field={cc} />}
          />
        ))}
      </ItemValidation>
    </>
  );
}

interface ItemValidationProps {
  field: Pick<DimensionValidationResult, "caption" | "isCustom" | "valid">;
  Icon: ComponentType<SvgIconProps>;
  itemSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  subMessage?: JSX.Element;
}
function ItemValidation({
  field,
  itemSx,
  containerSx,
  Icon,
  subMessage,
  children,
}: PropsWithChildren<ItemValidationProps>) {
  return (
    <Stack
      sx={{
        border: "1px solid",
        borderRadius: 1,
        px: 0.5,
        bgcolor: field.valid ? "inherit" : grey[50],
        ...containerSx,
        borderColor: "#F1F1F1",
      }}
    >
      <ItemValidationComponent field={field} Icon={Icon} containerSx={itemSx} subMessage={subMessage} />
      <Stack>{children}</Stack>
    </Stack>
  );
}
function ItemValid({ field }: { field: Pick<DimensionValidationResult, "valid" | "message"> }) {
  if (!field.valid) {
    return (
      <Typography variant="caption" color="secondary" sx={{ pl: 3.5 }}>
        {field.message}
      </Typography>
    );
  }

  return null;
}

function ItemValidationComponent({ field, containerSx, Icon, subMessage }: ItemValidationProps) {
  return (
    <>
      <Stack sx={{ ...containerSx }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 1 }}>
          <Icon sx={{ color: field.isCustom ? blue[600] : "secondary.light" }} />
          <Typography
            sx={{
              textDecoration: field.isCustom && !field.valid ? "line-through" : "none",
              textDecorationColor: (theme) => theme.palette.secondary.main,
            }}
          >
            {field.caption}
          </Typography>
          <HorizontalFill />
          {field.valid && <CheckRoundedIcon color="success" />}
          {!field.valid && <WarningRoundedIcon color="warning" />}
        </Stack>
        {subMessage}
      </Stack>
    </>
  );
}
