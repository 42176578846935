import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Menu } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import ActionMenuItem from "../../../../../shared/components/ActionMenuItem";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import RenameReportIcon from "../../../../../shared/icons/RenameReportIcon";
import { useGroupsContext } from "../../../../../shared/reporting/components/groups/contexts/GroupsContext";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { useClientContext } from "../../../../context/ClientContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { GroupType } from "../GroupType";
import DeleteGroupDialog from "./DeleteGroupDialog";
import RenameGroupDialog from "./RenameGroupDialog";

interface Props {
  anchorEl: HTMLElement | null;
  currentGroupId: string;
  showRename?: boolean;
  showDelete?: boolean;
  type: GroupType;
  onClose: () => void;
  onDuplicate?: (reportId: string) => void;
}

export const GroupActions = (props: Props) => {
  const { anchorEl, currentGroupId, showDelete, showRename, onClose, type } = props;

  const { clientCode } = useClientContext();
  const { groups: reportGroups } = useGroupsContext();
  const { sendNotification } = useNotificationContext();
  const { groups: locale } = useLocalization();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean | undefined>(undefined);
  const [openRenameDialog, setOpenRenameDialog] = useState<boolean | undefined>(undefined);
  const [open, setOpen] = useState(() => !!anchorEl);
  const groups = defined(reportGroups[clientCode]);

  const group = useMemo(() => {
    return groups.find((r) => r.id === currentGroupId);
  }, [groups, currentGroupId]);

  const onDeleteReport = useCallback(() => {
    setOpen(false);
    setOpenDeleteDialog(true);
  }, [setOpenDeleteDialog]);

  const onRenameReport = useCallback(() => {
    setOpen(false);
    setOpenRenameDialog(true);
  }, [setOpenRenameDialog]);

  useEffect(() => {
    if (openDeleteDialog === false || openRenameDialog === false) {
      onClose();
    }
  }, [openDeleteDialog, openRenameDialog, onClose]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => onClose()}
        slotProps={{
          paper: {
            sx: { width: "200px" },
          },
        }}
      >
        {showRename && (
          <ActionMenuItem key={"rename"} icon={<RenameReportIcon />} title="Rename Group" onClick={onRenameReport} />
        )}
        {showDelete && (
          <ActionMenuItem
            key={"delete"}
            icon={<DeleteOutlineOutlinedIcon />}
            title="Delete Group"
            onClick={onDeleteReport}
          />
        )}
      </Menu>
      {openDeleteDialog && group && (
        <DeleteGroupDialog
          group={group}
          onDeleted={() => sendNotification(locale.deleted)}
          onClose={() => setOpenDeleteDialog(false)}
          type={type}
        />
      )}
      {openRenameDialog && group && (
        <RenameGroupDialog
          group={group}
          onChanged={() => sendNotification(locale.renamed)}
          onClose={() => setOpenRenameDialog(false)}
        />
      )}
    </>
  );
};

export default GroupActions;
