import AddIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import BadgeDetached from "../../../../../../shared/components/BadgeDetached";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import { DataCollectionRequestDetails, RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import GeneralPageHeader from "../../../../common/GeneralPageHeader";
import DeleteSubmissionsDialog from "../../data-collection-dialogs/DeleteSubmissionsDialog";
import PublishSubmissionsDialog from "../../data-collection-dialogs/PublishSubmissionsDialog";
import RequestChangesForSubmissionsDialog from "../../data-collection-dialogs/RequestChangesForSubmissionsDialog";
import UnpublishSubmissionsDialog from "../../data-collection-dialogs/UnpublishSubmissionsDialog";
import AddSubmissionsDialog from "../dialogs/AddSubmissionsDialog";
import DeleteDataRequestDialog from "../dialogs/DeleteDataRequestDialog";
import EditDataRequestDialog from "../dialogs/EditDataRequestDialog";
import PublishDataRequestDialog from "../dialogs/PublishDataRequestDialog";
import DataRequestInfoSection from "./DataRequestInfoSection";
import { DataRequestPageContextProvider } from "./DataRequestPageContext";
import {
  allSubmissionStatusFilterValues,
  filterSubmissions,
  getFilterCounters,
  SubmissionsFilterState,
  SubmissionStatusFilterValue,
} from "./dataRequestPageFiltering";
import DataRequestPageTitle from "./DataRequestPageTitle";
import DataRequestSubmissionsGrid from "./DataRequestSubmissionsGrid";

interface DialogState {
  openDialog?:
    | "publish"
    | "delete"
    | "edit"
    | "publish_submissions"
    | "unpublish_submissions"
    | "request_changes"
    | "delete_submissions"
    | "add_company";
  submissions?: RequestSubmissionInfo[];
}

const DataRequestPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clientCode, hasPermissions } = useClientContext();

  const [dialogState, setDialogState] = useState<DialogState>({});

  const [submissionsFilter, setSubmissionsFilter] = useState<SubmissionsFilterState>({
    searchTerm: "",
    statusFilter: "All",
  });

  const getDataRequestDetails = useCallback(() => adminApi.getDataCollectionRequestDetails(defined(id)), [id]);

  const [dataRequest, fetchRequestError, { isFetching: isFetchingRequest, setData: setDataRequest }] =
    useFetch(getDataRequestDetails);

  const [templates, fetchTemplatesError, { isFetching: isFetchingTemplates }] = useFetch(
    adminApi.getDataCollectionRequestTemplates
  );

  const filterCounters = useMemo(() => getFilterCounters(dataRequest?.submissions ?? []), [dataRequest]);

  const fetchError = fetchRequestError || fetchTemplatesError;

  if (fetchError) {
    logError(fetchError, "[DataCollectionRequestPage] getDataRequestDetails");
    return <DataLoadingFailed title="Could not load data request details" />;
  }

  if (isFetchingRequest || isFetchingTemplates || dataRequest === undefined) {
    return <InlineLoader />;
  }

  const handleSearch = (value: string) => {
    setSubmissionsFilter((prev) => ({ ...prev, searchTerm: value.trim().toLowerCase() }));
  };

  const handleFilterChange = (_: React.MouseEvent, value: SubmissionStatusFilterValue | null) => {
    if (value === null) {
      return;
    }

    setSubmissionsFilter((prev) => ({ ...prev, statusFilter: value }));
  };

  const handlePublishClick = () => {
    setDialogState({ openDialog: "publish" });
  };

  const handlePublished = (updatedDataRequest: DataCollectionRequestDetails) => {
    setDialogState({});
    setDataRequest(updatedDataRequest);
  };

  const handleDeleteClick = () => {
    setDialogState({ openDialog: "delete" });
  };

  const handleDeleted = () => {
    setDialogState({});
    navigate(`/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataRequests}`);
  };

  const handleSaved = (updatedDataRequest: DataCollectionRequestDetails) => {
    setDialogState({});
    setDataRequest(updatedDataRequest);
  };

  const handleEditClick = () => {
    setDialogState({ openDialog: "edit" });
  };

  const handlePublishSubmissions = (submissions: RequestSubmissionInfo[]) => {
    setDialogState({ openDialog: "publish_submissions", submissions });
  };

  const handleUnpublishSubmissions = (submissions: RequestSubmissionInfo[]) => {
    setDialogState({ openDialog: "unpublish_submissions", submissions });
  };

  const handleRequestChangesForSubmissions = (submissions: RequestSubmissionInfo[]) => {
    setDialogState({ openDialog: "request_changes", submissions });
  };

  const handleDeleteSubmissions = (submissions: RequestSubmissionInfo[]) => {
    setDialogState({ openDialog: "delete_submissions", submissions });
  };

  const handleAddCompany = () => {
    setDialogState({ openDialog: "add_company" });
  };

  const hasPermissionsToManage = hasPermissions(["ManagePortfolioMonitoring"]);

  const filteredSubmissions = filterSubmissions(dataRequest.submissions, submissionsFilter);

  return (
    <DataRequestPageContextProvider
      hasPermissionsToManage={hasPermissionsToManage}
      onPublishSubmissions={handlePublishSubmissions}
      onUnpublishSubmissions={handleUnpublishSubmissions}
      onRequestChangesForSubmissions={handleRequestChangesForSubmissions}
      onDeleteSubmissions={handleDeleteSubmissions}
    >
      <GeneralPageHeader
        TitleComponent={<DataRequestPageTitle dataRequest={dataRequest} />}
        showDefaultBackButtonTo={`/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataRequests}`}
      >
        {hasPermissionsToManage && (
          <>
            {(dataRequest.status === "Draft" || dataRequest.status === "InProgress") && (
              <Button variant="outlined" color="secondary" startIcon={<SettingsIcon />} onClick={handleEditClick}>
                Settings
              </Button>
            )}
            {dataRequest.status === "Draft" && (
              <Button
                variant="contained"
                startIcon={<LanguageIcon />}
                onClick={handlePublishClick}
                disabled={dataRequest.submissions.length === 0}
              >
                Publish
              </Button>
            )}
            {dataRequest.status === "Draft" && (
              <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={handleDeleteClick}>
                Delete
              </Button>
            )}
          </>
        )}
      </GeneralPageHeader>

      <Stack spacing={2} flex={1} pt={2.5} px={3}>
        <DataRequestInfoSection dataRequest={dataRequest} />

        <Box display="flex" justifyContent="space-between">
          <ToggleButtonGroup value={submissionsFilter.statusFilter} exclusive onChange={handleFilterChange}>
            {allSubmissionStatusFilterValues.map((status) => (
              <ToggleButton key={status} value={status} sx={{ gap: 1 }}>
                {status} <BadgeDetached badgeContent={filterCounters[status]} max={99} showZero color="secondary" />
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Stack direction="row" spacing={1}>
            <SearchField debounceTimeMs={300} initialValue={submissionsFilter.searchTerm} onSearch={handleSearch} />
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddCompany}>
              Add Companies
            </Button>
          </Stack>
        </Box>

        <DataRequestSubmissionsGrid requestId={dataRequest.id} rows={filteredSubmissions} />
      </Stack>

      {dialogState.openDialog === "edit" && (
        <EditDataRequestDialog
          editedDataRequest={dataRequest}
          templates={templates ?? []}
          onClose={() => setDialogState({})}
          onSaved={handleSaved}
        />
      )}

      <PublishDataRequestDialog
        open={dialogState.openDialog === "publish"}
        onClose={() => setDialogState({})}
        onPublished={handlePublished}
        dataRequestId={dataRequest.id}
        dataRequestName={dataRequest.name}
      />

      <DeleteDataRequestDialog
        open={dialogState.openDialog === "delete"}
        onClose={() => setDialogState({})}
        onDeleted={handleDeleted}
        dataRequestId={dataRequest.id}
        dataRequestName={dataRequest.name}
      />

      {dialogState.submissions && (
        <PublishSubmissionsDialog
          dataRequestId={dataRequest.id}
          submissionIds={dialogState.submissions.map((s) => s.id)}
          recipientNames={dialogState.submissions.map((s) => s.recipientName)}
          open={dialogState.openDialog === "publish_submissions"}
          onClose={() => setDialogState({})}
          onApply={handleSaved}
        />
      )}

      {dialogState.submissions && (
        <UnpublishSubmissionsDialog
          dataRequestId={dataRequest.id}
          submissionIds={dialogState.submissions.map((s) => s.id)}
          recipientNames={dialogState.submissions.map((s) => s.recipientName)}
          open={dialogState.openDialog === "unpublish_submissions"}
          onClose={() => setDialogState({})}
          onApply={handleSaved}
        />
      )}

      {dialogState.submissions && (
        <RequestChangesForSubmissionsDialog
          dataRequestId={dataRequest.id}
          submissionIds={dialogState.submissions.map((s) => s.id)}
          recipientNames={dialogState.submissions.map((s) => s.recipientName)}
          open={dialogState.openDialog === "request_changes"}
          onClose={() => setDialogState({})}
          onApply={handleSaved}
        />
      )}

      {dialogState.submissions && (
        <DeleteSubmissionsDialog
          dataRequestId={dataRequest.id}
          submissionIds={dialogState.submissions.map((s) => s.id)}
          recipientNames={dialogState.submissions.map((s) => s.recipientName)}
          open={dialogState.openDialog === "delete_submissions"}
          onClose={() => setDialogState({})}
          onApply={handleSaved}
        />
      )}

      {dialogState.openDialog === "add_company" && (
        <AddSubmissionsDialog
          dataRequestId={dataRequest.id}
          assignedObjectIds={dataRequest.submissions.map((sub) => sub.recipientObjectId)}
          onClose={() => setDialogState({})}
          onApply={handleSaved}
        />
      )}
    </DataRequestPageContextProvider>
  );
};

export default DataRequestPage;
