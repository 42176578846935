import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { Validator } from "../../utilities/validators";

interface ChipsMultilineInputProps {
  onChange: (values: string[]) => void;
  values: string[];
  label?: string;
  placeholder?: string;
  inputValidator?: Validator<string>;
  availableValues?: string[];
  freeSolo?: boolean;
  removeDuplicatesOnPaste?: boolean;
  restrictedItems?: string[];
  restrictedItemsHelperText?: string;
  disabled?: boolean;
}

const ChipsMultilineInput = ({
  onChange,
  values,
  label,
  placeholder,
  inputValidator,
  availableValues,
  freeSolo,
  removeDuplicatesOnPaste,
  restrictedItems,
  restrictedItemsHelperText,
  disabled,
}: ChipsMultilineInputProps) => {
  const [inputValue, setInputValue] = useState("");

  const clearInputText = useDebounce(() => {
    setInputValue("");
  }, 10);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "Tab", ",", " "].includes(event.key)) {
      event.preventDefault();
      convertTextToChips();
    }
  };

  const convertTextToChips = () => {
    const newInputValue = inputValue.trim();
    if (newInputValue && validateInputValue(newInputValue)) {
      onChange([...values, newInputValue.toLocaleLowerCase()]);
      clearInputText();
    }
  };

  const handleDeleteChip = (_valueToDelete: string, index: number) => {
    values.splice(index, 1);
    onChange([...values]);
  };

  const validateInputValue = (valueInput: string) => {
    const value = valueInput.trim();
    if (!value) {
      return false;
    }
    if (inputValidator && !inputValidator(value).isValid) {
      return false;
    }
    if (availableValues && !freeSolo && !availableValues.includes(value)) {
      return false;
    }
    if (values.includes(value)) {
      return false;
    }
    return true;
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedText = event.clipboardData.getData("text");
    const newValues = pastedText
      .split(/[,\s]+/)
      .filter((value) => validateInputValue(value))
      .map((value) => value.trim().toLocaleLowerCase());

    if (newValues.length) {
      if (removeDuplicatesOnPaste) {
        onChange([...values, ...Array.from(new Set(newValues))]);
      } else {
        onChange([...values, ...newValues]);
      }

      clearInputText();
    }
  };

  const anyDuplicatesExist = new Set(values).size !== values.length;
  const anyRestrictedValuesExist =
    restrictedItems !== undefined && values.some((value) => restrictedItems.includes(value));

  return (
    <TextField
      label={label}
      placeholder={values.length > 0 ? "" : placeholder}
      variant="outlined"
      multiline
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      onPaste={handlePaste}
      onBlur={convertTextToChips}
      disabled={disabled}
      error={anyDuplicatesExist || anyRestrictedValuesExist}
      helperText={
        anyDuplicatesExist ? "There are duplicate entries" : anyRestrictedValuesExist ? restrictedItemsHelperText : ""
      }
      slotProps={{
        input: {
          sx: {
            gap: 1.5,
            flexWrap: "wrap",
            "& .MuiOutlinedInput-input": {
              display: "flex",
              width: values.length === 0 ? "100%" : "auto",
              minWidth: "50%",
            },
          },
          startAdornment: values.map((value, index) => {
            const alreadyExists = values.filter((v) => v === value).length > 1;
            const restricted = restrictedItems && restrictedItems.includes(value);
            return (
              <Chip
                key={index}
                color={alreadyExists || restricted ? "error" : "default"}
                variant="outlined"
                label={value}
                onDelete={() => handleDeleteChip(value, index)}
                disabled={disabled}
              />
            );
          }),
        },
      }}
    />
  );
};

export default ChipsMultilineInput;
