import { createContext, PropsWithChildren, useCallback, useContext } from "react";
import { ClientBranding, ClientType } from "../../shared/api/clientTypes";
import { AccessPermission } from "../../shared/api/types";
import { defined } from "../../shared/utilities/typeHelper";
import { MenuBadges } from "../api/adminApi";
import {
  AppDescriptor,
  ClientDictionaries,
  ClientSettings,
  CompanyType,
  FeatureToggle,
  PortfolioMonitoringAppDescriptor,
} from "../api/types/clientTypes";
import { useUserContext } from "./UserContext";

export interface Client {
  clientCode: string;
  permissions: AccessPermission[];
  branding: ClientBranding;
  organizationEmailDomain?: string;
  businessCentralApp?: AppDescriptor;
  investorRelationsApp?: AppDescriptor;
  investorPortalApp?: AppDescriptor;
  fundraisingApp?: AppDescriptor;
  portfolioMonitoringApp?: PortfolioMonitoringAppDescriptor;
  reportingPortalApp?: AppDescriptor;
  apiGatewayApp?: AppDescriptor;
  salesforceApp?: AppDescriptor;
  plaidApp?: AppDescriptor;
  yodleeApp?: AppDescriptor;
  saltEdgeApp?: AppDescriptor;
  mxApp?: AppDescriptor;
  qashqadeApp?: AppDescriptor;
  dealCloudApp?: AppDescriptor;
  entityLevelAccess?: boolean;
  clientType: ClientType;
  clientTitle: string;
  expenseManagementPortalApp?: AppDescriptor;
  dataBackupApp?: AppDescriptor;
  passthroughApp?: AppDescriptor;
  companyType: CompanyType;
}

interface Props {
  client: Client;
  clientSettings: ClientSettings;
  clientDictionaries: ClientDictionaries;
  menuBadges: MenuBadges | undefined;
  updateMenuBadges: () => void;
  onNewClientSelected: (clientCode: string) => void;
  updateUserData: () => void;
}

interface ContextValue extends Client {
  isFundAdmin: boolean;
  onNewClientSelected: (clientCode: string) => void;
  hasPermissions: (permissions: AccessPermission[]) => boolean;
  hasAnyPermission: (permissions: AccessPermission[]) => boolean;
  settings: ClientSettings;
  dictionaries: ClientDictionaries;
  menuBadges: MenuBadges | undefined;
  updateMenuBadges: () => void;
  updateUserData: () => void;
  isPortalFeatureEnabled: (feature: FeatureToggle) => boolean;
}

const ClientContext = createContext<ContextValue | undefined>(undefined);

export const ClientContextProvider = ({
  children,
  client,
  clientSettings,
  clientDictionaries,
  menuBadges,
  updateMenuBadges,
  onNewClientSelected,
  updateUserData,
}: PropsWithChildren<Props>) => {
  const { isBetaAccessEnabled } = useUserContext();

  const hasPermissions = useCallback(
    (permissions: AccessPermission[]) => permissions.every((p) => client.permissions.includes(p)),
    [client.permissions]
  );

  const hasAnyPermission = useCallback(
    (permissions: AccessPermission[]) => permissions.some((p) => client.permissions.includes(p)),
    [client.permissions]
  );

  const isPortalFeatureEnabled = (feature: FeatureToggle) =>
    clientSettings.enabledFeatures.includes(feature) ||
    (isBetaAccessEnabled && clientSettings.betaOnlyFeatures.includes(feature));

  return (
    <ClientContext.Provider
      value={{
        ...client,
        settings: clientSettings,
        dictionaries: clientDictionaries,
        menuBadges,
        updateMenuBadges,
        hasPermissions,
        hasAnyPermission,
        onNewClientSelected,
        isFundAdmin: client.clientType === "FundAdmin",
        updateUserData,
        isPortalFeatureEnabled,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  const client = useContext(ClientContext);
  return defined(client);
};
