import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import {
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FileIcon from "../../../../../shared/components/FileIcon";
import TableWithActions from "../../../../../shared/components/TableWithActions";
import { formatFileSize, getFileExtension } from "../../../../../shared/utilities/fileHelper";
import { FileInfo } from "../../../../api/types/fileTypes";

interface Props {
  files: FileInfo[];
  onDownloadFile: (fileDataCatalogueId: string) => void;
  showFileSize?: boolean;
  downloadingFileId?: string;
}

const ImportFilesTable = ({ files: sourceFiles, onDownloadFile, showFileSize, downloadingFileId }: Props) => {
  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <TableContainer>
        <TableWithActions
          sx={{
            "& .MuiTableRow-root": {
              "&:last-child": {
                td: {
                  borderBottom: 0,
                },
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {showFileSize && <TableCell sx={{ width: 120 }}>Size</TableCell>}
              <TableCell sx={{ width: 64 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sourceFiles.map((fileInfo) => (
              <TableRow key={fileInfo.fileName}>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FileIcon fileExtension={getFileExtension(fileInfo.fileName)} />
                    <Typography noWrap>{fileInfo.fileName}</Typography>
                  </Stack>
                </TableCell>
                {showFileSize && <TableCell>{formatFileSize(fileInfo.fileSize)}</TableCell>}
                <TableCell>
                  <IconButton
                    onClick={() => onDownloadFile(fileInfo.catalogueId)}
                    disabled={downloadingFileId === fileInfo.catalogueId}
                  >
                    {downloadingFileId === fileInfo.catalogueId ? (
                      <CircularProgress color="secondary" size={20} />
                    ) : (
                      <DownloadIcon color="action" />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableWithActions>
      </TableContainer>
    </Paper>
  );
};

export default ImportFilesTable;
