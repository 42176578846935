import { Stack, Typography } from "@mui/material";
import { GridColumnHeaderParams, GridValidRowModel } from "@mui/x-data-grid-premium";
import ExpandCollapseAllButton from "../ExpandCollapseAllButton";

interface Props<T extends GridValidRowModel> {
  params: GridColumnHeaderParams<T, unknown, unknown>;
  onExpandToggle?: (expanded: boolean) => void;
}

const DataGridGroupHeader = <T extends GridValidRowModel>({ params, onExpandToggle }: Props<T>) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ExpandCollapseAllButton onToggle={onExpandToggle} />
      <Typography variant="subtitle2">{params.colDef.headerName}</Typography>
    </Stack>
  );
};

export default DataGridGroupHeader;
