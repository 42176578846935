import { GRID_CHECKBOX_SELECTION_FIELD } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import { getCheckedGridSx } from "../../common/grid/gridStyles";
import UserAccessMatrixGridToolbar from "./UserAccessMatrixGridToolbar";
import { getColumnDefinitions, GridActions } from "./userAccessMatrixGridDataProvider";
import { UserAccessMatrixRow } from "./userAccessMatrixModel";

interface Props {
  rows: UserAccessMatrixRow[];
  categories: string[];
  isLoading?: boolean;
  actions: GridActions;
  hasPermissionsToManage: boolean;
  excludeColumns?: string[];
}

const UserAccessMatrixGrid = ({
  rows,
  categories,
  isLoading,
  actions,
  hasPermissionsToManage,
  excludeColumns,
}: Props) => {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const columns = useMemo(
    () => getColumnDefinitions(categories, actions, hasPermissionsToManage),
    [categories, actions, hasPermissionsToManage]
  );

  const visibilityModel = useMemo(
    () => Object.fromEntries((excludeColumns ?? []).map((col) => [col, false])),
    [excludeColumns]
  );

  const handleEditSelectedUsers = (ids: string[]) => {
    actions.onEditUsers(rows.filter((row) => ids.includes(row.id)));
  };

  const handleRemoveSelectedUsers = (ids: string[]) => {
    actions.onRemoveUsers(rows.filter((row) => ids.includes(row.id)));
  };

  const showToolbar = selectedRowIds.length > 0;

  return (
    <DataGrid<UserAccessMatrixRow>
      rows={rows}
      columns={columns}
      loading={isLoading}
      pinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_FIELD, "name"] }}
      noRowsText="No users"
      multilineRows
      checkboxSelection={hasPermissionsToManage}
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
      disableColumnReorder
      disableColumnSorting
      columnVisibilityModel={visibilityModel}
      sx={(theme) => getCheckedGridSx(theme, showToolbar)}
      slots={{
        toolbar: () =>
          showToolbar ? (
            <UserAccessMatrixGridToolbar
              selectedIds={selectedRowIds}
              setSelectedIds={setSelectedRowIds}
              onEditSelectedUsers={handleEditSelectedUsers}
              onRemoveSelectedUsers={handleRemoveSelectedUsers}
            />
          ) : null,
      }}
    />
  );
};

export default UserAccessMatrixGrid;
