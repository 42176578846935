import RemoveIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { GridColDef, GridGroupingColDefOverride } from "@mui/x-data-grid-premium";
import { Link } from "react-router";
import DataGridGroupHeader from "../../../../../../../shared/components/grid/DataGridGroupHeader";
import { convertISODate } from "../../../../../../../shared/utilities/dateUtils";
import { PortalRole } from "../../../../../../api/types/accessTypes";
import { FundraisingAccessItem } from "../../../../../../api/types/fundraisingTypes";
import { useClientContext } from "../../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../../routes";
import PortalRoleChip from "../../../../../common/PortalRoleChip";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingAccessGridContext } from "./FundraisingAccessGridContext";

export const applySearchFilter = (searchValue: string, allRows: FundraisingAccessItem[]) => {
  const searchTerm = searchValue.toLowerCase().trim();
  if (searchTerm.length === 0) {
    return allRows;
  }

  return allRows.filter(
    (row) =>
      row.investorName.toLowerCase().includes(searchTerm) ||
      row.contactName.toLowerCase().includes(searchTerm) ||
      (row.contactEmail ?? "").toLowerCase().includes(searchTerm)
  );
};

export const groupingColumnDefinition: GridGroupingColDefOverride<FundraisingAccessItem> = {
  leafField: "contactName",
  renderHeader: (params) => <DataGridGroupHeader params={params} />,
  flex: 1,
};

const CategoriesCell = ({ row }: { row: FundraisingAccessItem }) => {
  const { fundraisingCategories } = useFundraisingDetailsPageContext();

  const { categoryIds } = row;
  const text =
    categoryIds.length === fundraisingCategories.length
      ? "All"
      : `${categoryIds.length}/${fundraisingCategories.length}`;

  return (
    <Tooltip
      arrow
      title={
        <Stack>
          {fundraisingCategories.map((c) =>
            categoryIds.includes(c.externalId) ? (
              <Box key={c.externalId} sx={{ whiteSpace: "pre-line" }}>
                {c.name}
              </Box>
            ) : (
              <Box
                key={c.externalId}
                sx={{ whiteSpace: "pre-line", textDecorationLine: "line-through", color: grey[500] }}
              >
                {c.name}
              </Box>
            )
          )}
        </Stack>
      }
    >
      <Typography>{text}</Typography>
    </Tooltip>
  );
};

const ActionsCell = ({ row }: { row: FundraisingAccessItem }) => {
  const { isContentEditable } = useFundraisingDetailsPageContext();
  const { onEditAccess, onRemoveAccess } = useFundraisingAccessGridContext();

  if (!isContentEditable) {
    return null;
  }

  return (
    <Stack direction="row" width="100%" spacing={0.5}>
      <IconButton size="small" onClick={() => onEditAccess(row)}>
        <EditIcon />
      </IconButton>
      <IconButton size="small" onClick={() => onRemoveAccess(row)}>
        <RemoveIcon color="error" />
      </IconButton>
    </Stack>
  );
};

const ContactCell = ({ row }: { row: FundraisingAccessItem }) => {
  const { clientCode } = useClientContext();

  return (
    <Stack
      component={Link}
      to={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}/${row.contactId}/main`}
      target="_blank"
      rel="noopener noreferrer"
      spacing={0.5}
      width="100%"
      pl={5}
      sx={(t) => ({
        textDecoration: "none",
        color: "unset",
        ":hover": { color: t.palette.primary.main, cursor: "pointer" },
      })}
    >
      <Typography variant="subtitle2" noWrap>
        {row.contactName}
      </Typography>
      <Typography variant="caption" color="text.secondary" noWrap>
        {row.contactEmail}
      </Typography>
    </Stack>
  );
};

const orderedFundraisingPortalRoles: PortalRole[] = ["LP_Admin", "LP_Member"];

export const columnDefinitions: GridColDef<FundraisingAccessItem>[] = [
  {
    field: "investorName",
    headerName: "",
    renderCell: ({ rowNode, field, value }) => {
      if (rowNode.type === "group" && field === rowNode.groupingField) {
        return "";
      }

      return (
        <Typography variant="subtitle2" noWrap>
          {value}
        </Typography>
      );
    },
  },
  {
    field: "contactName",
    headerName: "Name",
    cellClassName: "highlighted-action",
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return <ContactCell row={row} />;
    },
  },
  {
    field: "roles",
    headerName: "Role",
    minWidth: 160,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      const resolvedRole = orderedFundraisingPortalRoles.find((role) => row.roles.includes(role)) ?? "None";
      return <PortalRoleChip role={resolvedRole} />;
    },
  },
  {
    field: "categories",
    headerName: "Access Categories",
    minWidth: 160,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return <CategoriesCell row={row} />;
    },
  },
  {
    field: "registrationDate",
    headerName: "Registration Status",
    width: 160,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return row.registrationDate ? (
        <Stack spacing={0.5} width="100%">
          <Typography>Registered</Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {convertISODate(row.registrationDate)}
          </Typography>
        </Stack>
      ) : (
        <Typography>Not Registered</Typography>
      );
    },
  },
  {
    field: "actions",
    headerName: "",
    width: 80,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row, rowNode }) => (rowNode.type === "group" ? null : <ActionsCell row={row} />),
  },
];
