import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { FocusEvent, useRef } from "react";
import { MenuItemStyled } from "../../../../../../../shared/components/MenuItemStyled";
import { InvoiceDeal } from "../../../../../../api/adminApi";
import { InvoiceDetailsLine } from "../invoiceDetailsState";

interface Props {
  params: GridRenderCellParams<InvoiceDetailsLine, string>;
  deals: InvoiceDeal[];
}

const InvoiceLineDealSelect = ({ params, deals }: Props) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = async (_: React.SyntheticEvent, newValue: InvoiceDeal | null) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: deals.find((deal) => deal.id === newValue?.id)?.id,
    });
  };

  const selectedValue = deals.find((deal) => deal.id === value) || null;

  return (
    <Autocomplete
      fullWidth
      options={deals}
      openOnFocus
      value={selectedValue}
      onChange={handleChange}
      getOptionLabel={({ description }) => `${description || ""}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <MenuItemStyled {...props} key={option.id}>
          <Stack direction="row" spacing={2} py={0.25}>
            <Typography>{option.description || ""}</Typography>
          </Stack>
        </MenuItemStyled>
      )}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          slotProps={{
            input: {
              ...params.InputProps,
              onFocus: (event: FocusEvent<HTMLInputElement>) => {
                setTimeout(() => {
                  event.target.select();
                });
              },
            },
          }}
          autoFocus
        />
      )}
      sx={{ "& input": { letterSpacing: "0.17px" } }}
    />
  );
};

export default InvoiceLineDealSelect;
