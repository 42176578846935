import { Alert, AlertTitle, Box, Typography } from "@mui/material";

interface Props {
  errors: string[];
  onClose: () => void;
}

export const ImportErrorsAlert = ({ errors, onClose }: Props) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <Box pl={3} pr={5} pt={2.5}>
      <Alert severity="warning" onClose={onClose}>
        <AlertTitle>Data Import Errors</AlertTitle>
        <Typography sx={{ whiteSpace: "pre-line" }}>{errors.join("\n")}</Typography>
      </Alert>
    </Box>
  );
};
