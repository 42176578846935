import NumberTextField from "../../../NumberTextField";

interface Props {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const MetricNumberField = ({ value, onChange, readOnly, disabled }: Props) => {
  return (
    <NumberTextField
      fullWidth
      disabled={disabled}
      numberValue={value}
      onNumberChange={onChange}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
    />
  );
};

export default MetricNumberField;
