import { Divider, Grid2, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { reportingPeriodCaptionsMap } from "../../../../../../shared/utilities/enumCaptions";
import { autoFormatCamelCase } from "../../../../../../shared/utilities/stringHelper";
import { DataCollectionRequestDetails } from "../../../../../api/types/dataCollectionTypes";

interface Props {
  dataRequest: DataCollectionRequestDetails;
}

const DividerItem = () => (
  <Grid2>
    <Divider orientation="vertical" />
  </Grid2>
);

const InfoItem = ({ title, subtitle }: { title: string; subtitle: string }) => (
  <Grid2 size="grow">
    <Stack spacing={0.5}>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Stack>
  </Grid2>
);

const ProgressItem = ({ dataRequest }: Props) => {
  return (
    <Grid2 size={4} sx={{ pr: 2.5 }}>
      <Stack spacing={1}>
        <Typography variant="caption" color="text.secondary">
          Progress
        </Typography>
        <Stack direction="row" spacing={1.5} alignItems="center" width="100%">
          <LinearProgress
            variant="determinate"
            value={
              dataRequest.submissions.length > 0
                ? (dataRequest.submittedSubmissions * 100) / dataRequest.submissions.length
                : 0
            }
            sx={{ width: "calc(100% - 36px)", backgroundColor: blueGrey[50] }}
          />

          <Typography sx={{ whiteSpace: "nowrap" }}>
            {`${dataRequest.submittedSubmissions.toLocaleString()}/${dataRequest.submissions.length.toLocaleString()}`}
          </Typography>
        </Stack>
      </Stack>
    </Grid2>
  );
};

const DataRequestInfoSection = ({ dataRequest }: Props) => {
  return (
    <Paper variant="outlined" sx={{ py: 2, pl: 2 }}>
      <Grid2 container spacing={2.5}>
        <InfoItem title="Scenario" subtitle={dataRequest.templateScenario} />
        <DividerItem />
        <InfoItem title="Period" subtitle={reportingPeriodCaptionsMap[dataRequest.templateReportingPeriod]} />
        <DividerItem />
        <InfoItem title="Template" subtitle={dataRequest.templateName} />
        <DividerItem />
        <InfoItem title="Reporting Date" subtitle={convertISODate(dataRequest.reportingDate)} />
        <DividerItem />
        <InfoItem title="Recipient Type" subtitle={autoFormatCamelCase(dataRequest.auditory)} />
        <DividerItem />
        <ProgressItem dataRequest={dataRequest} />
      </Grid2>
    </Paper>
  );
};

export default DataRequestInfoSection;
