import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import RecordCounter from "../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../shared/components/inputs/SearchField";
import UserAccessMatrixGrid from "./UserAccessMatrixGrid";
import { User, UserAccess, UserAccessMatrixRow, getUserAccessMatrixRows } from "./userAccessMatrixModel";

interface Props {
  categories: string[];
  hasPermissionsToManage: boolean;
  accessItems: UserAccess[];
  allUsers: User[];
  onAddUsers: () => void;
  onEditUsers: (rows: UserAccessMatrixRow[]) => void;
  onRemoveUsers: (rows: UserAccessMatrixRow[]) => void;
  isLoading: boolean;
  excludeColumns?: string[];
}

const searchRows = (searchTerm: string, allRows: UserAccessMatrixRow[]) =>
  searchTerm
    ? allRows.filter(
        (row) => row.name.toLowerCase().includes(searchTerm) || row.email.toLowerCase().includes(searchTerm)
      )
    : allRows;

const UserAccessMatrix = ({
  categories,
  hasPermissionsToManage,
  accessItems,
  allUsers,
  onAddUsers,
  onEditUsers,
  onRemoveUsers,
  isLoading,
  excludeColumns,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const rows = useMemo(() => getUserAccessMatrixRows(accessItems, allUsers), [accessItems, allUsers]);

  const handleSearch = (value: string) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const filteredRows = searchRows(searchTerm, rows);

  return (
    <>
      <Stack spacing={2.5} height="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <RecordCounter records={filteredRows.length} totalRecords={rows.length} hide={isLoading} />
          <Stack spacing={1} direction="row">
            <SearchField initialValue={searchTerm} debounceTimeMs={300} onSearch={handleSearch} disabled={isLoading} />
            {hasPermissionsToManage && (
              <Button variant="contained" onClick={onAddUsers} disabled={isLoading} startIcon={<AddIcon />}>
                Add New
              </Button>
            )}
          </Stack>
        </Box>

        <UserAccessMatrixGrid
          rows={filteredRows}
          categories={categories}
          isLoading={isLoading}
          actions={{ onEditUsers, onRemoveUsers }}
          hasPermissionsToManage={hasPermissionsToManage}
          excludeColumns={excludeColumns}
        />
      </Stack>
    </>
  );
};

export default UserAccessMatrix;
