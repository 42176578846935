import { Alert, AlertTitle, Box } from "@mui/material";
import { DataImportInfo } from "../../../../../api/types/dataImportTypes";

interface Props {
  dataImport: DataImportInfo;
  onClose: () => void;
}

const GeneralImportErrorsAlert = ({ dataImport, onClose }: Props) => {
  const generalErrors = dataImport.validationResult?.generalErrors ?? [];

  if (generalErrors.length === 0) {
    return null;
  }

  return (
    <Box pt={2.5} px={3}>
      <Alert severity="error" onClose={onClose}>
        {generalErrors.filter(Boolean).map((errorText, index) => (
          <AlertTitle key={index}>{errorText}</AlertTitle>
        ))}
        Please fix the issues and run validation once again
      </Alert>
    </Box>
  );
};

export default GeneralImportErrorsAlert;
