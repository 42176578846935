import { JSX, PropsWithChildren } from "react";
import PopupMenu from "../../../shared/components/PopupMenu";
import { lightTheme } from "../../themes";
import { cornersBeforePositions } from "../common/corners";

interface AccountPopupMenuProps {
  renderElement: JSX.Element;
}

const AccountPopupMenu = ({ renderElement: anchor, children }: PropsWithChildren<AccountPopupMenuProps>) => {
  return (
    <PopupMenu
      theme={lightTheme}
      anchor={anchor}
      paperProps={(theme) => ({
        width: theme.spacing(34),
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        "&:before": cornersBeforePositions.left("bottom", 20),
        "& .MuiAvatar-root": {
          width: 40,
          height: 40,
          mr: 1,
        },
        marginLeft: theme.spacing(2),
      })}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      {children}
    </PopupMenu>
  );
};

export default AccountPopupMenu;
