import { except, intersect } from "../../../../shared/utilities/arrayHelper";
import { PortalRole, PortalRoleWithNoneValue } from "../../../api/types/accessTypes";
import { ObjectContact, ObjectContactRequest, ObjectInternalUserPermissions } from "../../../api/types/objectTypes";
import { AccessValue } from "../../common/AccessEditor";

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface UserAccess {
  id: string;
  role?: PortalRole;
  categories: string[];
}

export interface UserAccessMatrixRow extends User, UserAccess {}

export const getUserAccessMatrixRows = (accessItems: UserAccess[], allUsers: User[]): UserAccessMatrixRow[] => {
  const allUsersMap = allUsers.reduce((result, user) => {
    result.set(user.id, user);
    return result;
  }, new Map<string, User>());

  return accessItems
    .map((accessItem) => {
      const user = allUsersMap.get(accessItem.id);
      return {
        ...accessItem,
        id: accessItem.id,
        name: user?.name || "Unknown",
        email: user?.email || "",
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

// Contacts
export const mapObjectContactToUserAccess = (objectContact: ObjectContact): UserAccess => ({
  id: objectContact.contactId,
  role: objectContact.role,
  categories: objectContact.categories,
});

export const mapUserAccessToObjectContactRequest = (userAccess: UserAccess): ObjectContactRequest => ({
  contactId: userAccess.id,
  role: userAccess.role,
  categories: userAccess.categories,
});

// Internal Users
export const mapObjectInternalUserPermissionsToUserAccess = (
  userPemissions: ObjectInternalUserPermissions
): UserAccess => ({
  id: userPemissions.userId,
  categories: userPemissions.categories,
});

export const mapUserAccessToObjectInternalUserPermissionsRequest = (
  userAccess: UserAccess
): ObjectInternalUserPermissions => ({
  userId: userAccess.id,
  categories: userAccess.categories,
});

// Editing

export const getCategoryOptions = (categories: string[]): { id: string; label: string }[] =>
  categories
    .map((category) => ({
      id: category,
      label: category,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getInitialAccessValueForBulkEditing = (
  editedRows: UserAccessMatrixRow[],
  { setRole }: { setRole: boolean }
): AccessValue => {
  const selectedRole = setRole
    ? editedRows.reduce<PortalRoleWithNoneValue | undefined>((result, row, index) => {
        const role = row.role ?? "None";
        return index === 0 ? role : result === role ? result : undefined;
      }, undefined)
    : undefined;

  const commonCategories = editedRows.reduce<string[]>(
    (result, row, index) => (index === 0 ? row.categories : intersect(result, row.categories)),
    []
  );

  const allCategories = editedRows.flatMap((row) => row.categories);
  const indeterminateCategoryIds = except(allCategories, commonCategories);

  return {
    selectedRole,
    selectedCategoryIds: commonCategories,
    indeterminateCategoryIds,
  };
};
