import { Alert } from "@mui/material";
import { useCrmConnectorContext } from "./CrmConectorContext";
import { FullResyncState } from "./crmConnectorState";

const AlertMessage = ({ status, errorMessage }: FullResyncState) => {
  if (status === "success") {
    return (
      <>
        Full re-synchronization started. All logs you can see in tab <strong>Logs</strong>.
      </>
    );
  }

  let msg = "Full re-synchronization failed";
  if (errorMessage) {
    msg += `: ${errorMessage}`;
  }

  return <>{msg}</>;
};

const FullResyncInfo = () => {
  const { fullResyncState, onFullResyncStateUpdate } = useCrmConnectorContext();

  const { status, errorMessage } = fullResyncState;

  if (status === "hidden" || status === "starting") {
    return null;
  }

  return (
    <Alert severity={status} onClose={() => onFullResyncStateUpdate({ status: "hidden" })}>
      <AlertMessage status={status} errorMessage={errorMessage} />
    </Alert>
  );
};

export default FullResyncInfo;
