import { Autocomplete, Box, Button, Checkbox, Chip, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import HorizontalFill from "../../../components/HorizontalFill";
import { ReportAccessType, ReportAuthorization, UserForSharing } from "../../api/biClient.types";
import { AccessModeMenu } from "./AccessModeMenu";
import { getLabelByAccess, getMaxAccessByPermisions } from "./utils";

interface UserSelectionProps {
  availableUsers: UserForSharing[];
  authorization: ReportAuthorization;
  disabled: boolean;
  onUsersSelected: (users: UserForSharing[], access: ReportAccessType) => void;
}

export default function UserSelection({
  availableUsers,
  authorization,
  disabled,
  onUsersSelected,
}: UserSelectionProps) {
  const [selectedUsers, setSelectedUsers] = useState<UserForSharing[]>([]);
  const [accessModeMenuOpen, setAccessModeMenuOpen] = useState(false);
  const [menuRef, setMenuRef] = useState<HTMLDivElement>();
  const [selectedAccess, setSelectedAccess] = useState<ReportAccessType>(ReportAccessType.Read);

  useEffect(() => {
    const allowedUsers = selectedUsers.filter((user) => {
      switch (selectedAccess) {
        case ReportAccessType.FullAccess:
          return user.permissions.includes("ShareReports");
        case ReportAccessType.Write:
          return user.permissions.includes("ManageReports");
        case ReportAccessType.Read:
          return user.permissions.includes("ViewReports");
        default:
          return false;
      }
    });
    if (allowedUsers.length !== selectedUsers.length) {
      setSelectedUsers(allowedUsers);
    }
  }, [selectedAccess, selectedUsers]);

  const selectedAccessLabel = useMemo(() => getLabelByAccess(selectedAccess), [selectedAccess]);

  const usersWithoutAuthorization = useMemo(() => {
    return availableUsers.filter((u) => !authorization.users.some((au) => au.userId === u.userId));
  }, [availableUsers, authorization.users]);

  const usersByAccessLevel = useMemo(() => {
    return usersWithoutAuthorization.filter((user) => {
      switch (selectedAccess) {
        case ReportAccessType.FullAccess:
          return user.permissions.includes("ShareReports");
        case ReportAccessType.Write:
          return user.permissions.includes("ManageReports");
        case ReportAccessType.Read:
          return user.permissions.includes("ViewReports");
        default:
          return false;
      }
    });
  }, [selectedAccess, usersWithoutAuthorization]);

  const handleAddUsers = useCallback(() => {
    onUsersSelected(selectedUsers, selectedAccess);
    setSelectedUsers([]);
  }, [onUsersSelected, selectedUsers, selectedAccess]);

  return (
    <Stack direction={"row"} spacing={1} alignItems={"start"}>
      <Autocomplete
        multiple
        fullWidth
        value={selectedUsers}
        options={usersByAccessLevel}
        disabled={disabled}
        disableCloseOnSelect
        getOptionLabel={(option) => `${option.name}${option.email}`}
        getOptionKey={(option) => option.userId}
        sx={(theme) => ({
          "&.MuiAutocomplete-hasPopupIcon .MuiInputBase-root": {
            pr: 10,
            '[data-id="access-button"]': {
              right: 35,
            },
          },
          "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiInputBase-root": {
            pr: 15,
            '[data-id="access-button"]': {
              right: 63,
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.divider,
          },
        })}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              label={option.name}
              variant="outlined"
              sx={(theme) => ({ borderColor: theme.palette.divider, fontSize: "12px", fontWeight: 500 })}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            <Stack direction={"row"} flex={1}>
              <Stack>
                <Typography variant="body1">{option.name}</Typography>
                <Typography variant="body2" color={"secondary"}>
                  {option.email}
                </Typography>
              </Stack>
              <HorizontalFill />
              <Typography variant="body2" color={"secondary"}>
                {getMaxAccessByPermisions(option.permissions)}
              </Typography>
            </Stack>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={selectedUsers.length === 0 ? "Add Member, comma or space separated" : ""}
            slotProps={{
              input: {
                ...params.InputProps,
                sx: {
                  minHeight: 40,
                },
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <Box
                      ref={setMenuRef}
                      data-id="access-button"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        transform: "translate(0, -50%)",
                        cursor: disabled ? "default" : "pointer",
                      }}
                    >
                      <Typography variant="body2" onClick={() => !disabled && setAccessModeMenuOpen(true)}>
                        {selectedAccessLabel}
                      </Typography>
                    </Box>
                  </>
                ),
              },
            }}
          />
        )}
        onChange={(_, newValue) => {
          setSelectedUsers(newValue);
        }}
      />
      <Box>
        <Button
          variant="contained"
          sx={{ height: 39 }}
          disabled={selectedUsers.length === 0 || disabled}
          size="medium"
          onClick={handleAddUsers}
        >
          Add
        </Button>
      </Box>
      <AccessModeMenu
        menuRef={menuRef}
        open={accessModeMenuOpen}
        access={selectedAccess}
        allowedAccesses={[ReportAccessType.FullAccess, ReportAccessType.Write, ReportAccessType.Read]}
        onSelect={setSelectedAccess}
        onClose={() => setAccessModeMenuOpen(false)}
      />
    </Stack>
  );
}
