import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowFwdIcon from "@mui/icons-material/ArrowForwardRounded";
import { Box, Button, Divider, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageFooter from "../../../common/GeneralPageFooter";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import DeleteDataImportDialog from "../DeleteDataImportDialog";
import ImportErrorsWarningDialog from "../ImportErrorsWarningDialog";
import { useApplyDataImport, useCreateDataImport, useValidateDataImport } from "../dataImportOperations";
import { ImportCsvDataContextProvider } from "./ImportCsvDataContext";
import ImportCsvDataTargetSelectionStep from "./data-selection/ImportCsvDataTargetSelectionStep";
import ImportCsvDataFilesUploadStep from "./files-upload/ImportCsvDataFilesUploadStep";
import {
  finishCreatingImportAction,
  finishValidateImportAction,
  getCreateDataImportRequest,
  getCurrentStepIndex,
  getInitialState,
  initTargetOptions,
  isNextStepAllowed,
  isPreviousStepAllowed,
  nextStepAction,
  previousStepAction,
  setImportCreationErrorAction,
  stepLabels,
  updateApplyImportStatusAction,
  updateCreateImportStatusAction,
  updateValidateImportStatusAction,
} from "./importCsvDataPageState";
import ReviewImportActions from "./review-import/ReviewImportActions";
import ReviewImportStep from "./review-import/ReviewImportStep";

interface DialogState {
  openDialog?: "skip_errors" | "delete_import";
  errorsCount?: number;
}

const getDataImportChangesTotals = withErrorHandling(adminApi.getDataImportChangesTotals);
const discardDataImport = withErrorHandling(adminApi.discardDataImport);

const ImportCsvDataPage = () => {
  const { clientCode } = useClientContext();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const createDataImportWithTimeout = useCreateDataImport((status) => {
    setState(updateCreateImportStatusAction(status));
  });

  const applyDataImportWithTimeout = useApplyDataImport((status) => {
    setState(updateApplyImportStatusAction(status));
  });

  const validateDataImportWithTimeout = useValidateDataImport((status) => {
    setState(updateValidateImportStatusAction(status));
  });

  const [state, setState] = useState(getInitialState(locationState?.preSelectedObjectType));
  const [dialogState, setDialogState] = useState<DialogState>({});

  const [dataImportOptions, fetchError, { isFetching: isFetchingDataImportOptions }] = useFetch(
    adminApi.getDataImportOptions,
    (payload) => setState(initTargetOptions(payload))
  );

  if (fetchError) {
    logError(fetchError, "[ImportCsvDataPage] getDataImportOptions");
    return <DataLoadingFailed title="Could not retrieve available import data targets" />;
  }

  const isLoading = dataImportOptions === undefined || isFetchingDataImportOptions;

  const createImport = async () => {
    const [result, error] = await createDataImportWithTimeout(getCreateDataImportRequest(state));

    if (result) {
      const { dataImport, dataImportMetadata } = result;
      setState(finishCreatingImportAction(dataImport, dataImportMetadata));
    } else if (!error.isCanceledRequest) {
      setState(setImportCreationErrorAction(error.message));
      logError(error, "[ImportCsvDataPage] createImport");
    }
  };

  const applyImport = async () => {
    if (!state.dataImport) {
      return;
    }

    const [success, error] = await applyDataImportWithTimeout(state.dataImport.id);

    if (success) {
      sendNotification("Data import has been applied successfully");
      navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}`);
    } else if (!error.isCanceledRequest) {
      setState(updateApplyImportStatusAction(undefined));
      sendNotificationError(`Error occurred when applying data import: ${error.message}`);
      logError(error, "[ImportCsvDataPage] applyDataImportWithTimeout");
    }
  };

  const validateImport = async () => {
    if (!state.dataImport) {
      return;
    }

    const [result, error] = await validateDataImportWithTimeout(state.dataImport.id);

    if (result) {
      const { dataImport, dataImportMetadata } = result;
      setState(finishValidateImportAction(dataImport, dataImportMetadata));
    } else if (!error.isCanceledRequest) {
      setState(updateValidateImportStatusAction(undefined));
      sendNotificationError("Validation Error: " + error.message);
      logError(error, "[ImportCsvDataPage] validateDataImportWithTimeout");
    }
  };

  const handlePreviousClick = () => {
    if (state.currentStep.key === "review" && state.dataImport) {
      discardDataImport(state.dataImport.id);
    }

    setState(previousStepAction());
  };

  const handleNextClick = async () => {
    if (state.currentStep.key === "upload") {
      await createImport();
      return;
    }

    if (state.currentStep.key === "review") {
      if (!state.dataImport) {
        return;
      }

      const [resp, totalsFetchError] = await getDataImportChangesTotals(state.dataImport.id);
      if (totalsFetchError) {
        logError(totalsFetchError, "[ImportCsvDataPage] getTotalErrorsCountForAllFiles");
        sendNotificationError("Error occurred when applying data import");
        return;
      }

      if (resp.errorsCount > 0) {
        setDialogState({ openDialog: "skip_errors", errorsCount: resp.errorsCount });
      } else {
        await applyImport();
      }

      return;
    }

    setState(nextStepAction());
  };

  const handleConfirmImport = async () => {
    setDialogState({});
    await applyImport();
  };

  const handleDeleteImport = () => {
    setDialogState({ openDialog: "delete_import" });
  };

  const handleImportDeleted = () => {
    setDialogState({});
    navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}`);
  };

  const backButtonPath =
    locationState?.referrerPath || `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.importData}`;

  return (
    <ImportCsvDataContextProvider pageState={state} setPageState={setState}>
      <GeneralPageHeader title="Data Import" showDefaultBackButtonTo={backButtonPath}>
        {state.dataImport && (
          <ReviewImportActions
            dataImport={state.dataImport}
            showDownload={state.currentStep.key === "review" && state.dataImport.state !== "Created"}
            showValidate={state.currentStep.key === "review"}
            showDelete={state.currentStep.key === "review"}
            onValidate={validateImport}
            onDelete={handleDeleteImport}
          />
        )}
      </GeneralPageHeader>
      <Box py={1.5} px={3} display="flex" justifyContent="center">
        <Stepper activeStep={getCurrentStepIndex(state)}>
          {stepLabels.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Divider />

      {isLoading && <InlineLoader />}
      {!isLoading && state.currentStep.key === "data" && <ImportCsvDataTargetSelectionStep />}
      {!isLoading && state.currentStep.key === "upload" && <ImportCsvDataFilesUploadStep />}
      {!isLoading && state.currentStep.key === "review" && <ReviewImportStep />}

      <GeneralPageFooter>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handlePreviousClick}
          sx={{ visibility: state.currentStep.previousStepKey ? "visible" : "hidden" }}
          disabled={isLoading || !isPreviousStepAllowed(state)}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          endIcon={state.currentStep.key === "review" ? undefined : <ArrowFwdIcon />}
          onClick={handleNextClick}
          disabled={isLoading || !isNextStepAllowed(state)}
        >
          {state.currentStep.key === "review" ? "Start Import" : "Next"}
        </Button>
      </GeneralPageFooter>
      <ImportErrorsWarningDialog
        open={dialogState.openDialog === "skip_errors"}
        onClose={() => setDialogState({})}
        onConfirm={handleConfirmImport}
        errorsCount={dialogState.errorsCount}
      />
      <DeleteDataImportDialog
        deletingImport={state.dataImport}
        open={dialogState.openDialog === "delete_import"}
        onClose={() => setDialogState({})}
        onConfirm={handleImportDeleted}
      />
    </ImportCsvDataContextProvider>
  );
};

export default ImportCsvDataPage;
