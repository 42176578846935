import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import {
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FileIcon from "../../../../../../shared/components/FileIcon";
import TableWithActions from "../../../../../../shared/components/TableWithActions";
import { formatFileSize, getFileExtension } from "../../../../../../shared/utilities/fileHelper";
import { useImportCsvDataContext } from "../ImportCsvDataContext";
import { ImportSourceFileInfo, deleteSourceFileAction } from "../importCsvDataPageState";

interface Props {
  sourceFile: ImportSourceFileInfo;
}

const ImportSourceFileTable = ({ sourceFile }: Props) => {
  const { setPageState } = useImportCsvDataContext();

  return (
    <TableContainer component={Paper} variant="outlined" sx={{ width: "100%" }}>
      <TableWithActions>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell sx={{ width: 120 }}>Size</TableCell>
            <TableCell sx={{ width: 64 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Stack direction="row" spacing={1} alignItems="center">
                {sourceFile.status !== "in_progress" && (
                  <FileIcon fileExtension={getFileExtension(sourceFile.fileInfo.fileName)} />
                )}
                {sourceFile.status === "in_progress" && <CircularProgress size={20} color="secondary" />}
                <Stack>
                  <Typography noWrap>{sourceFile.fileInfo.fileName}</Typography>
                  {sourceFile.status === "error" && sourceFile.errorMessage && (
                    <Typography variant="caption" color="error">
                      {sourceFile.errorMessage}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </TableCell>
            <TableCell>{formatFileSize(sourceFile.fileInfo.fileSize)}</TableCell>
            <TableCell className="table-row-actions">
              {sourceFile.status !== "in_progress" && (
                <IconButton onClick={() => setPageState(deleteSourceFileAction(sourceFile.targetOptionKey))}>
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </TableWithActions>
    </TableContainer>
  );
};

export default ImportSourceFileTable;
