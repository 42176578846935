import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { getErrorMessage } from "../../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import {
  AccessLevelEntity,
  allOrganizationRoles,
  CompanyChange,
  EntitiesAccessConfig,
  OrganizationMember,
  OrganizationRole,
} from "../../../../../api/types/userManagementTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import {
  alignCompaniesAccessToOrgRole,
  CompanyAccessLevel,
  CompanyProductWithRoles,
  CompanyWithAccess,
  CompanyWithRoles,
  filterOrganizationRoles,
  getCompanyProductsWithRolesForOrgRole,
  isExternalUserEmail,
} from "../definitions";
import { useMembersContext } from "../MembersContext";
import MemberInvitedDialogIcon from "./MemberInvitedDialogIcon";

const requireAtLeastOneInEntityLevelAccess = false;

interface ContextValue {
  organizationRole: OrganizationRole | undefined;
  updateOrganizationRole: (role: OrganizationRole) => void;
  emails: string[];
  updateEmails: (emails: string[]) => void;
  selectedCompanies: CompanyWithAccess[];
  updateSelectedCompanies: (companies: CompanyWithAccess[]) => void;
  onCompanyDelete: (clientCode: string) => void;
  selectedCompanyAccess: [CompanyWithAccess, CompanyAccessLevel] | undefined;
  updateSelectedCompanyAccess: (company: CompanyWithAccess, accessLevel: CompanyAccessLevel) => void;
  isValidConfiguration: boolean;
  distributePermissions: () => void;
  sendInvites: () => void;
  handleClose: () => void;
  getDialogTitle: () => string;
  managedMember: OrganizationMember | undefined;
  applyMemberChanges: () => void;
  allowedOrganizationRoles: readonly OrganizationRole[];
  isCurrentUser: (idOrEmail: string) => boolean;
  getAvailableProductsWithRoles: (clientCode: string) => CompanyProductWithRoles[];
}

const InviteMembersContext = createContext<ContextValue | undefined>(undefined);

export const InviteMembersContextProvider = ({ children }: PropsWithChildren) => {
  const { organizationEmailDomain } = useClientContext();
  const { sendNotificationError } = useNotificationContext();

  const [emails, setEmails] = useState<string[]>([]);
  const [organizationRole, setOrganizationRole] = useState<OrganizationRole>();
  const [selectedCompanies, setSelectedCompanies] = useState<CompanyWithAccess[]>([]);
  const [selectedCompanyAccess, setSelectedCompanyAccess] = useState<
    [CompanyWithAccess, CompanyAccessLevel] | undefined
  >();
  const [isValidConfiguration, setIsValidConfiguration] = useState(false);
  const [managedMember, setManagedMember] = useState<OrganizationMember | undefined>();
  const [currentUserUpdated, setCurrentUserUpdated] = useState(false);

  const {
    clientTitle,
    setIsSending,
    setConfirmationPopupData,
    inviteMembersDialogData,
    setInviteMembersDialogData,
    setSelectedMembersIds,
    updatePendingInvites,
    updateMembers,
    companies,
    getAccessLevelEntities,
    members,
    hasAccessToManageOrganizationOwners,
    isCurrentUser,
  } = useMembersContext();

  const isValid = useCallback(() => emails.length > 0 && !!organizationRole, [emails.length, organizationRole]);

  useEffect(() => {
    if (!isValid()) {
      setIsValidConfiguration(false);
      return;
    }

    if (inviteMembersDialogData?.action === "invite") {
      const memberExists = members.map((member) => member.email).some((email) => emails.includes(email));
      if (memberExists) {
        setIsValidConfiguration(false);
        return;
      }
    }

    for (const company of selectedCompanies) {
      if (company.roles.length === 0) {
        setIsValidConfiguration(false);
        return;
      }

      if (requireAtLeastOneInEntityLevelAccess && company.entities && company.entities.length === 0) {
        const companyInfo = companies.find((c) => c.clientCode === company.clientCode);
        if (companyInfo?.entityLevelAccess) {
          setIsValidConfiguration(false);
          return;
        }
      }
    }
    setIsValidConfiguration(true);
  }, [companies, emails, organizationRole, selectedCompanies, isValid, inviteMembersDialogData?.action, members]);

  const updateSelectedCompanies = (companies: CompanyWithAccess[]) => {
    const sortedCompanies = companies.sort((a, b) => {
      if (a.clientCode < b.clientCode) {
        return -1;
      }
      if (a.clientCode > b.clientCode) {
        return 1;
      }
      return 0;
    });

    setSelectedCompanies(sortedCompanies);
  };

  const onConfirmationClose = () => {
    setConfirmationPopupData(undefined);
    if (inviteMembersDialogData?.action === "invite") {
      handleClose();
    }
  };

  const handleClose = () => {
    const isCurrentUserUpdated = currentUserUpdated;
    clearEverything();
    setInviteMembersDialogData(undefined);
    if (isCurrentUserUpdated) {
      window.location.reload();
    }
  };

  const getSelectedEntitiesAccessPerCompany = () => {
    return selectedCompanies
      .filter((c) => {
        const company = companies.find((company) => company.clientCode === c.clientCode);
        return company?.entityLevelAccess;
      })
      .map((c) => ({
        [c.clientCode]: {
          allowedEntityIds: c.entities.map((e) => e.id),
          allowAccessToAllEntities: c.accessToAllEntities,
        },
      }))
      .reduce((acc, val) => ({ ...acc, ...val }), {});
  };

  const sendInvites = async () => {
    if (!isValid() || !organizationRole) {
      return;
    }

    setIsSending(true);

    const entitiesAccess: Record<string, EntitiesAccessConfig> = getSelectedEntitiesAccessPerCompany();
    const roles: CompanyWithRoles[] = selectedCompanies.map((c) => ({ clientCode: c.clientCode, roles: c.roles }));

    try {
      const response = await adminApi.sendInvitations({
        emails,
        organizationRole,
        roles,
        entitiesAccess,
      });
      if (response.success) {
        await Promise.all([updatePendingInvites(), updateMembers()]);
        setConfirmationPopupData({
          title: "Invitation successfully sent!",
          description: `${emails.length} members invited.`,
          onClose: onConfirmationClose,
          icon: <MemberInvitedDialogIcon />,
        });
        setIsSending(false);
        return;
      }
      await handleError(response.error?.message);
    } catch (error) {
      logError(error, "InviteMembersContext.sendInvites");
      await handleError("Failed to send invitations");
    }
  };

  const handleApplyMemberChanges = async () => {
    if (!isValid() || !managedMember) {
      return;
    }

    setIsSending(true);

    try {
      const accessChanges: CompanyChange[] = selectedCompanies.map((c) => {
        return {
          clientCode: c.clientCode,
          roles: c.roles,
        };
      });

      managedMember.clientsAccess.forEach((access) => {
        if (!accessChanges.some((a) => a.clientCode === access.clientCode)) {
          accessChanges.push({
            clientCode: access.clientCode,
            roles: [],
          });
        }
      });

      const response = await adminApi.updateOrganizationMemberAccess(managedMember.userId, {
        accessChanges,
        newOrganizationRole:
          organizationRole !== filterOrganizationRoles(managedMember.organizationPermissions.roles)[0]
            ? organizationRole
            : undefined,
        entitiesAccess: getSelectedEntitiesAccessPerCompany(),
      });

      if (!response.success) {
        await handleError(response.error?.message);
        return;
      }

      updateMembers();

      setConfirmationPopupData({
        title: "Member successfully updated!",
        description: `${managedMember.email} updated.`,
        onClose: onConfirmationClose,
        icon: <MemberInvitedDialogIcon />,
      });

      setIsSending(false);

      if (isCurrentUser(managedMember.userId)) {
        setCurrentUserUpdated(true);
      }
    } catch (error) {
      await handleError(getErrorMessage(error));
    }
  };

  const handleError = async (error?: string) => {
    setIsSending(false);
    sendNotificationError(error || "Failed to send invitations.");
  };

  const clearEverything = () => {
    setEmails([]);
    setOrganizationRole(undefined);
    setSelectedCompanies([]);
    setSelectedMembersIds([]);
    setSelectedCompanyAccess(undefined);
    setManagedMember(undefined);
    setCurrentUserUpdated(false);
  };

  const updateOrganizationRole = (orgRole: OrganizationRole) => {
    setOrganizationRole(orgRole);
    setSelectedCompanyAccess(undefined);
    updateSelectedCompanies(alignCompaniesAccessToOrgRole(orgRole, selectedCompanies));
  };

  const isAnyExternalUserSelected = (emailAddresses: string[]) =>
    emailAddresses.some((email) => isExternalUserEmail(email, organizationEmailDomain ?? ""));

  const allowedOrganizationRoles: readonly OrganizationRole[] =
    hasAccessToManageOrganizationOwners && !isAnyExternalUserSelected(emails)
      ? allOrganizationRoles
      : allOrganizationRoles.filter((role) => role !== "Org_Owner");

  const updateEmails = (newEmails: string[]) => {
    setEmails(newEmails);

    if (isAnyExternalUserSelected(newEmails) && organizationRole === "Org_Owner") {
      updateOrganizationRole("Org_Member");
    }
  };

  useEffect(() => {
    if (inviteMembersDialogData?.member) {
      const { organizationPermissions, clientsAccess } = inviteMembersDialogData.member;
      const role = filterOrganizationRoles(organizationPermissions.roles)[0];
      if (role && allowedOrganizationRoles.includes(role)) {
        setOrganizationRole(role);
      }

      const memberCompanies: CompanyWithAccess[] = clientsAccess
        .filter((ca) => companies.some((c) => c.clientCode === ca.clientCode))
        .map((access) => {
          const entities = getAccessLevelEntities(access.clientCode);
          const accessEntities = (access.clientPermissions.entitiesAccess || [])
            .map((id) => {
              return entities.find((entity) => entity.id === id);
            })
            .filter((e) => e !== undefined) as AccessLevelEntity[];

          return {
            clientCode: access.clientCode,
            roles: access.clientPermissions.roles,
            entities: accessEntities,
            accessToAllEntities: access.clientPermissions.allowAccessToAllEntities,
          };
        });
      updateSelectedCompanies(memberCompanies);

      if (inviteMembersDialogData.action === "manage") {
        setManagedMember(inviteMembersDialogData.member);
        updateEmails([inviteMembersDialogData.member.email]);
      }
    }
    if (allowedOrganizationRoles.length === 1) {
      setOrganizationRole(allowedOrganizationRoles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteMembersDialogData]);

  const getAvailableProductsWithRoles = useCallback(
    (clientCode: string) => {
      if (!organizationRole) {
        return [];
      }

      let result = getCompanyProductsWithRolesForOrgRole(organizationRole);

      const isInvestorRelationsAvailable = companies.some(
        (c) => c.clientCode === clientCode && c.availableApplications.includes("InvestorRelations")
      );

      const isExpenseManagementAvailable = companies.some(
        (c) => c.clientCode === clientCode && c.availableApplications.includes("ExpenseManagement")
      );

      const isPortfolioMonitoringAvailable = companies.some(
        (c) => c.clientCode === clientCode && c.availableApplications.includes("PortfolioMonitoring")
      );

      if (!isInvestorRelationsAvailable) {
        result = result.filter((p) => p.key !== "InvestorRelationsManagement");
      }

      if (!isExpenseManagementAvailable) {
        result = result.filter((p) => p.key !== "ExpenseManagement");
      }

      if (!isPortfolioMonitoringAvailable) {
        result = result.filter((p) => p.key !== "PortfolioMonitoring");
      }

      return result;
    },
    [companies, organizationRole]
  );

  return (
    <InviteMembersContext.Provider
      value={{
        organizationRole,
        emails,
        updateOrganizationRole,
        updateEmails,
        selectedCompanies,
        updateSelectedCompanies,
        getAvailableProductsWithRoles,
        onCompanyDelete: (clientCode: string) => {
          if (selectedCompanyAccess && selectedCompanyAccess[0].clientCode === clientCode) {
            setSelectedCompanyAccess(undefined);
          }
          updateSelectedCompanies(selectedCompanies.filter((company) => company.clientCode !== clientCode));
        },
        selectedCompanyAccess,
        updateSelectedCompanyAccess: (company: CompanyWithAccess, accessLevel: CompanyAccessLevel) => {
          setSelectedCompanyAccess([company, accessLevel]);
        },
        isValidConfiguration,
        sendInvites,
        distributePermissions: () => {
          if (!selectedCompanyAccess) {
            return;
          }
          const [company, accessLevel] = selectedCompanyAccess;
          const updatedCompanies: CompanyWithAccess[] = selectedCompanies.map((c) => {
            if (accessLevel === "Product") {
              return {
                ...c,
                roles: [...company.roles],
              };
            }

            if (
              accessLevel === "Entity" &&
              companies.find((c) => c.clientCode === company.clientCode && c.entityLevelAccess)
            ) {
              const entities = getAccessLevelEntities(company.clientCode) || [];
              const filteredEntities = entities.filter((entity) => company.entities.some((e) => e.id === entity.id));
              return {
                ...c,
                entities: filteredEntities,
                accessToAllEntities: c.accessToAllEntities,
              };
            }

            return c;
          });
          updateSelectedCompanies(updatedCompanies);
        },
        handleClose,
        getDialogTitle: () => {
          return managedMember ? `Manage user of ${clientTitle}` : `Invite users to ${clientTitle}`;
        },
        managedMember,
        applyMemberChanges: handleApplyMemberChanges,
        allowedOrganizationRoles,
        isCurrentUser,
      }}
    >
      {children}
    </InviteMembersContext.Provider>
  );
};

export const useInviteMembersContext = () => {
  const inviteMembersContext = useContext(InviteMembersContext);
  return defined(inviteMembersContext);
};
