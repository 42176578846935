import {
  FundPortalCoreBrandingSettings,
  FundPortalCoreBrandingSettingsUpdate,
  RelativeLogoSize,
  SignOnSettings,
  UpdateSignOnSettingsRequest,
} from "../../../../api/types/investorPortalTypes";

export interface SavedPortalStyleSettings {
  portalSettings: FundPortalCoreBrandingSettings;
  signOnSettings: SignOnSettings;
}

export interface PortalStyleSettings {
  // Portal branding settings
  appBarColor: string;
  appBarTextColor: string;
  mainColor: string;
  logoTypeUrl: string;
  logoTypeFile: File | undefined;
  logoTypeSize: RelativeLogoSize;
  logoMarkUrl: string;
  logoMarkFile: File | undefined;
  faviconUrl: string;
  faviconFile: File | undefined;
  bannerFontColor: string;
  bannerUrl: string;
  bannerFile: File | undefined;
  // Sign-in page settings
  clientLogoUrl: string | undefined;
  clientLogoFile: File | undefined;
  primaryThemeColor: string;
  useBackgroundImage: boolean;
  backgroundColor: string;
  backgroundImageUrl: string | undefined;
  backgroundImageFile: File | undefined;
}

export const getInitialPortalStyleSettings = (): PortalStyleSettings => ({
  appBarColor: "",
  appBarTextColor: "",
  mainColor: "",
  logoTypeUrl: "",
  logoTypeFile: undefined,
  logoTypeSize: "Medium",
  logoMarkUrl: "",
  logoMarkFile: undefined,
  faviconUrl: "",
  faviconFile: undefined,
  bannerFontColor: "",
  bannerUrl: "",
  bannerFile: undefined,
  clientLogoUrl: "",
  clientLogoFile: undefined,
  primaryThemeColor: "",
  useBackgroundImage: false,
  backgroundColor: "#ffffff",
  backgroundImageUrl: "",
  backgroundImageFile: undefined,
});

export const getBrandingUpdateRequest = (
  state: PortalStyleSettings,
  savedPortalSettings: FundPortalCoreBrandingSettings
): FundPortalCoreBrandingSettingsUpdate => ({
  appBarColor: state.appBarColor === savedPortalSettings.appBarColor ? undefined : state.appBarColor,
  appBarTextColor: state.appBarTextColor === savedPortalSettings.appBarTextColor ? undefined : state.appBarTextColor,
  mainColor: state.mainColor === savedPortalSettings.mainColor ? undefined : state.mainColor,
  logoTypeSize: state.logoTypeSize === savedPortalSettings.logoTypeSize ? undefined : state.logoTypeSize,
  faviconUrlImage: state.faviconFile,
  logoTypeUrlImage: state.logoTypeFile,
  logoMarkUrlImage: state.logoMarkFile,
  bannerFontColor: state.bannerFontColor === savedPortalSettings.bannerFontColor ? undefined : state.bannerFontColor,
  bannerUrlImage: state.bannerFile,
});

export const getSignOnUpdateRequest = (
  state: PortalStyleSettings,
  savedSignOnSettings: SignOnSettings
): UpdateSignOnSettingsRequest => ({
  clientLogo: state.clientLogoFile,
  primaryThemeColor:
    state.primaryThemeColor === savedSignOnSettings.primaryThemeColor ? undefined : state.primaryThemeColor,
  useBackgroundImage:
    state.useBackgroundImage === savedSignOnSettings.useBackgroundImage ? undefined : state.useBackgroundImage,
  backgroundColor: state.backgroundColor === savedSignOnSettings.backgroundColor ? undefined : state.backgroundColor,
  backgroundImage: state.backgroundImageFile,
});

export const isBrandingStateDirty = (
  state: PortalStyleSettings,
  savedPortalSettings: FundPortalCoreBrandingSettings
): boolean => {
  const brandingRequest = getBrandingUpdateRequest(state, savedPortalSettings);
  return Object.values(brandingRequest).some((value) => value !== undefined);
};

export const isSignOnStateDirty = (state: PortalStyleSettings, savedSignOnSettings: SignOnSettings): boolean => {
  const signOnRequest = getSignOnUpdateRequest(state, savedSignOnSettings);
  return Object.values(signOnRequest).some((value) => value !== undefined);
};

// Actions

type StateAction = (state: PortalStyleSettings) => PortalStyleSettings;

export const initFromSavedSettingsAction =
  (savedSettings: SavedPortalStyleSettings): StateAction =>
  (state) => ({
    ...state,
    appBarColor: savedSettings.portalSettings.appBarColor,
    appBarTextColor: savedSettings.portalSettings.appBarTextColor,
    mainColor: savedSettings.portalSettings.mainColor,
    logoTypeUrl: savedSettings.portalSettings.logoTypeUrl,
    logoTypeFile: undefined,
    logoTypeSize: savedSettings.portalSettings.logoTypeSize,
    logoMarkFile: undefined,
    logoMarkUrl: savedSettings.portalSettings.logoMarkUrl,
    faviconUrl: savedSettings.portalSettings.faviconUrl,
    faviconFile: undefined,
    bannerFontColor: savedSettings.portalSettings.bannerFontColor,
    bannerUrl: savedSettings.portalSettings.bannerUrl,
    bannerFile: undefined,
    clientLogoUrl: savedSettings.signOnSettings.clientLogoUrl,
    clientLogoFile: undefined,
    primaryThemeColor: savedSettings.signOnSettings.primaryThemeColor ?? savedSettings.portalSettings.mainColor,
    useBackgroundImage: savedSettings.signOnSettings.useBackgroundImage,
    backgroundColor: savedSettings.signOnSettings.backgroundColor ?? "#ffffff",
    backgroundImageUrl: savedSettings.signOnSettings.backgroundImageUrl,
    backgroundImageFile: undefined,
  });

export const updateStyleSettingAction =
  (update: Partial<PortalStyleSettings>): StateAction =>
  (state) => ({
    ...state,
    ...update,
  });
