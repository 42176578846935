import { Grid2 } from "@mui/material";
import { useCallback } from "react";
import { FormCellValue, MetricExtensionsService } from "../../../api/dataCollectionTypes";
import { DictionaryItem, EntityOptionFieldType } from "../../../api/dictionaryTypes";
import { DataSubmissionLayout, ResolvedBlockContent } from "../../../api/inputFormTypes";
import ScrollableFlexContainer from "../../ScrollableFlexContainer";
import DataSubmissionFormBlock from "./DataSubmissionFormBlock";
import { DataSubmissionFormContextProvider } from "./DataSubmissionFormContext";

interface Props {
  recipientObjectId: string;
  layout: DataSubmissionLayout;
  blockContents: ResolvedBlockContent[];
  isSubmissionEditable: boolean;
  globalDictionaries: Partial<Record<EntityOptionFieldType, DictionaryItem[]>>;
  metricExtensionsService: MetricExtensionsService;
  onBlockCellValueEdit: (blockId: string, formCellValue: FormCellValue) => void;
}

const DataSubmissionForm = ({
  recipientObjectId,
  layout,
  blockContents,
  isSubmissionEditable,
  globalDictionaries,
  metricExtensionsService,
  onBlockCellValueEdit,
}: Props) => {
  const getBlockContent = useCallback(
    (blockId: string) => blockContents.find((blockContent) => blockContent.blockId === blockId)?.content,
    [blockContents]
  );

  return (
    <DataSubmissionFormContextProvider
      recipientObjectId={recipientObjectId}
      isSubmissionEditable={isSubmissionEditable}
      globalDictionaries={globalDictionaries}
      getBlockContent={getBlockContent}
      metricExtensionsService={metricExtensionsService}
      onBlockCellValueEdit={onBlockCellValueEdit}
    >
      <ScrollableFlexContainer>
        <Grid2 container sx={{ flex: 1, py: 2.5, px: 3 }} spacing={1}>
          {layout.blocks.map((block) => (
            <DataSubmissionFormBlock key={block.id} block={block} />
          ))}
        </Grid2>
      </ScrollableFlexContainer>
    </DataSubmissionFormContextProvider>
  );
};

export default DataSubmissionForm;
