import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Typography, useTheme } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { GridColDef } from "@mui/x-data-grid-premium";
import { NumericGridTableBlockRow, TableBlockColumn } from "../../../../api/inputFormTypes";
import { makeLighterBackgroundFromColor } from "../../../../utilities/colorHelper";
import { formatNumber } from "../../../../utilities/formatters";
import ColorTag from "../../../ColorTag";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { usePortfolioMonitoringInputTableContext } from "./PortfolioMonitoringInputTableContext";
import ScenarioColumnHeader from "./ScenarioColumnHeader";
import { calculateTotals } from "./tableTotalsCalculator";

export type RowModel = NumericGridTableBlockRow & {
  isNew?: boolean;
};

const NameCell = ({ row }: { row: RowModel }) => {
  const theme = useTheme();
  const { isSubmissionEditable } = useDataSubmissionFormContext();
  const { onAddMetricExtension } = usePortfolioMonitoringInputTableContext();

  if (row.type === "Metric" || row.type === "MetricExtension") {
    return (
      <>
        <Typography noWrap pl={row.indentationLevel * 2} flexGrow={1}>
          {row.name}
        </Typography>
        {row.isNew && (
          <ColorTag
            text="New"
            color={makeLighterBackgroundFromColor(theme.palette.info.main)}
            textColor={theme.palette.info.main}
            fontWeight={500}
          />
        )}
      </>
    );
  }

  if (row.type === "ExtendedMetricSection") {
    return (
      <>
        <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2} flexGrow={1}>
          {row.name}
        </Typography>
        {isSubmissionEditable && (
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => onAddMetricExtension(row.metricId, row.name)}
            tabIndex={-1}
          >
            Add
          </Button>
        )}
      </>
    );
  }

  return (
    <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2}>
      {row.name}
    </Typography>
  );
};

const formatNumberValue = (value: number) => formatNumber(value, { maximumFractionDigits: 0 });

const ValuesDiff = ({ previous, current }: { previous: number; current: number }) => {
  return (
    <Typography component="span">
      <Typography component="span" bgcolor={red[50]} sx={{ textDecoration: "line-through" }}>
        {formatNumberValue(previous)}
      </Typography>
      <Typography component="span" ml={1} bgcolor={green[50]}>
        {formatNumberValue(current)}
      </Typography>
    </Typography>
  );
};

export const getColumns = (
  columnDefinitions: TableBlockColumn[],
  isSubmissionEditable: boolean
): GridColDef<RowModel>[] => {
  const scenarioColumnDefinitions = columnDefinitions.filter((column) => column.type === "Scenario");

  const metricColumn: GridColDef<RowModel> = {
    field: "name",
    headerName: "Metric",
    flex: 2,
    minWidth: 300,
    editable: false,
    cellClassName: ({ row }) => {
      if (row.type === "Total") {
        return "total-cell";
      }

      if (
        (row.type === "Metric" || row.type === "MetricExtension" || row.type === "ExtendedMetricSection") &&
        row.isSecondary
      ) {
        return "secondary-name-cell";
      }

      return "";
    },
    renderCell: ({ row }) => <NameCell row={row} />,
  };

  const scenarioColumns: GridColDef<RowModel>[] = scenarioColumnDefinitions.map((columnDef) => ({
    field: columnDef.id,
    flex: 1,
    minWidth: 150,
    type: "number",
    editable: isSubmissionEditable && columnDef.isEditable,
    colSpan: (_, row) => (row.type === "Section" || row.type === "ExtendedMetricSection" ? 8 : 0),
    renderHeader: () => <ScenarioColumnHeader columnDefinition={columnDef} />,
    headerClassName: () => (columnDef.isEditable ? "" : "readonly-header"),
    cellClassName: ({ row }) => {
      if (row.type === "Total") {
        return "total-cell";
      }

      if ((row.type === "Metric" || row.type === "MetricExtension") && !columnDef.isEditable) {
        return "readonly-cell";
      }

      return "";
    },
    valueGetter: (_, row) => {
      if (row.type === "Total" || row.type === "Metric" || row.type === "MetricExtension") {
        return row.values[columnDef.id];
      }

      return undefined;
    },
    valueSetter: (newValue, row) => {
      if (row.type === "Metric" || row.type === "MetricExtension") {
        return { ...row, values: { ...row.values, [columnDef.id]: newValue ?? undefined } };
      }

      return row;
    },
    valueFormatter: (value: number | null | undefined, row) => {
      if (columnDef.isEditable && row.type !== "Total") {
        return typeof value === "number" ? formatNumberValue(value) : "";
      }

      return !value ? "-" : formatNumberValue(value);
    },
    renderCell:
      columnDef.isEditable && columnDef.dateRange === "Previous"
        ? ({ row, formattedValue, value }) => {
            if (typeof value !== "number") {
              return formattedValue;
            }

            if (row.type !== "Metric" && row.type !== "MetricExtension") {
              return formattedValue;
            }

            const previousValue = row.previousValues[columnDef.id];
            if (!previousValue || previousValue === value) {
              return formattedValue;
            }

            return <ValuesDiff previous={previousValue} current={value} />;
          }
        : undefined,
  }));

  return [metricColumn, ...scenarioColumns];
};

export const getRows = (rows: NumericGridTableBlockRow[]): RowModel[] => {
  return calculateTotals(rows);
};
