import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import DeletePersonIcon from "../../../icons/DeletePersonIcon";
import {
  mapObjectInternalUserPermissionsToUserAccess,
  mapUserAccessToObjectInternalUserPermissionsRequest,
  UserAccess,
  UserAccessMatrixRow,
} from "./userAccessMatrixModel";

interface Props {
  objectId: string;
  objectType: string;
  removedRows: UserAccessMatrixRow[];
  currentAccessItems: UserAccess[];
  onClose: () => void;
  onSave: (updatedAccessItems: UserAccess[]) => void;
}

const updateObjectInternalUserPermissions = withErrorHandling(adminApi.updateObjectInternalUserPermissions);

const RemoveInternalUserAccessDialog = ({
  objectId,
  objectType,
  removedRows,
  currentAccessItems,
  onClose,
  onSave,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);

  const handleSave = async () => {
    const payload = {
      userPermissions: currentAccessItems
        .filter((item) => !removedRows.some((r) => r.id === item.id))
        .map(mapUserAccessToObjectInternalUserPermissionsRequest),
    };

    setSaving(true);
    const [updatedUsers, error] = await updateObjectInternalUserPermissions(objectType, objectId, payload);
    setSaving(false);

    if (error) {
      logError(error, "[RemoveInternalUserAccessDialog] updateObjectInternalUserPermissions");
      sendNotificationError("Could not remove user(s)");
      return;
    }

    sendNotification("User(s) removed successfully");
    onSave(updatedUsers.map(mapObjectInternalUserPermissionsToUserAccess));
    onClose();
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Remove</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DeletePersonIcon />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              {removedRows.length === 1 ? "Remove user?" : "Remove users?"}
            </Typography>
            {removedRows.length === 1 && (
              <Typography color="text.secondary" textAlign="center">
                You&apos;re about to remove <strong>{removedRows[0]?.name}</strong> from the portfolio company. Access
                to this company will be lost.
              </Typography>
            )}
            {removedRows.length > 1 && (
              <Typography color="text.secondary" textAlign="center">
                You&apos;re about to remove <strong>{removedRows.length} users</strong> from the portfolio company.
                Access to this company will be lost.
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={isSaving} onClick={handleSave}>
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveInternalUserAccessDialog;
