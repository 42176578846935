import crmConnectorsApi, { CrmSyncLogs } from "../../../../api/crmConnectorsApi";
import LogsWrapper from "../../../common/syncLogs/LogsWrapper";
import { useCrmConnectorContext } from "../CrmConectorContext";
import LogsTable from "./LogsTable";

interface Props {
  excludeColumns: string[];
}

const Logs = ({ excludeColumns }: Props) => {
  const { crmApiName } = useCrmConnectorContext();

  return (
    <LogsWrapper<CrmSyncLogs>
      fetchLogsFromApi={(params) => crmConnectorsApi[crmApiName].getExecutionLogs(params)}
      renderTable={(logItems) => <LogsTable logItems={logItems} excludeColumns={excludeColumns} />}
    />
  );
};

export default Logs;
