import SaveIcon from "@mui/icons-material/SaveAltRounded";
import { Button, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { FileDownloadInfo } from "../../../../../shared/api/types";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import PreviewFileDialog from "../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../shared/services/downloadFile";
import adminApi, { FundraisingDocument } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import CustomDataGridHeaderToolbar from "../../../common/grid/CustomDataGridHeaderToolbar";
import { getCheckedGridSx } from "../../../common/grid/gridStyles";
import { getColumnDefinitions } from "./fundraisingDocumentsGridDataProvider";

interface Props {
  documents: FundraisingDocument[];
  categories: Category[];
  excludeColumns?: string[];
  isLoading?: boolean;
  onNavigateToDocumentActivity?: (document: FundraisingDocument) => void;
}

const getFundraisingFileDownloadInfo = withErrorHandling(adminApi.getFundraisingFileDownloadInfo);
const getDownloadUrlForMultipleFiles = withErrorHandling(adminApi.getDownloadUrlForMultipleFilesFromFundraisings);

const FundraisingDocumentsGrid = ({
  documents,
  categories,
  excludeColumns,
  isLoading,
  onNavigateToDocumentActivity,
}: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();
  const { sendNotificationError } = useNotificationContext();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [documentPreviewState, setDocumentPreviewState] = useState<FileDownloadInfo>();

  const getFileDownloadInfo = useCallback(
    async (document: FundraisingDocument) => {
      const [downloadInfo, error] = await getFundraisingFileDownloadInfo(document.fundraisingId, document.id);
      if (error) {
        logError(error, "[FundraisingDocumentsGrid] downloadFileData");
        sendNotificationError("Could not download document");
        return undefined;
      }

      return downloadInfo;
    },
    [sendNotificationError]
  );

  const handleDownloadDocument = useCallback(
    async (document: FundraisingDocument) => {
      const fileInfo = await getFileDownloadInfo(document);
      if (fileInfo) {
        downloadFileFromUrl(fileInfo.downloadUrl);
      }
    },
    [getFileDownloadInfo]
  );

  const handlePreviewDocument = useCallback(
    async (document: FundraisingDocument) => {
      const fileInfo = await getFileDownloadInfo(document);
      if (fileInfo) {
        setDocumentPreviewState(fileInfo);
      }
    },
    [getFileDownloadInfo]
  );

  const handleNavigateToFundraising = useCallback(
    (document: FundraisingDocument) => {
      navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.fundraisings}/${document.fundraisingId}`);
    },
    [clientCode, navigate]
  );

  const handleDownloadMultipleDocumentsClick = useCallback(async () => {
    const documentIdentifiers = documents
      .filter((d) => selectedIds.includes(d.id))
      .map((d) => ({ fundraisingId: d.fundraisingId, fundraisingDocumentId: d.id }));

    if (documentIdentifiers.length === 0) {
      return;
    }

    const [url, error] = await getDownloadUrlForMultipleFiles(documentIdentifiers, "fundraising_documents.zip");

    if (error) {
      logError(error, "[FundStructureDocumentsGrid] getDownloadUrlForSelectedDocuments");
      sendNotificationError("Could not download documents");
      return;
    }

    downloadFileFromUrl(url);
  }, [documents, selectedIds, sendNotificationError]);

  const columns = useMemo(
    () =>
      getColumnDefinitions(
        excludeColumns ?? [],
        categories,
        handlePreviewDocument,
        handleDownloadDocument,
        handleNavigateToFundraising,
        onNavigateToDocumentActivity
      ),
    [
      categories,
      excludeColumns,
      handleDownloadDocument,
      handleNavigateToFundraising,
      handlePreviewDocument,
      onNavigateToDocumentActivity,
    ]
  );

  const showToolbar = !!selectedIds.length;

  return (
    <Stack spacing={2.5} sx={{ height: "100%" }}>
      <DataGrid<FundraisingDocument>
        checkboxSelection
        rowSelectionModel={selectedIds}
        getRowId={(row) => row.id}
        sx={(theme) => getCheckedGridSx(theme, showToolbar)}
        columns={columns}
        loading={isLoading}
        rows={documents}
        noRowsText="No documents"
        slots={{
          toolbar: () =>
            showToolbar ? (
              <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
                <Button
                  disabled={selectedIds.length === 0 || isLoading}
                  onClick={handleDownloadMultipleDocumentsClick}
                  color="secondary"
                  variant="text"
                  startIcon={<SaveIcon />}
                >
                  Download
                </Button>
              </CustomDataGridHeaderToolbar>
            ) : null,
        }}
        disableColumnReorder
        disableColumnSorting
        onRowSelectionModelChange={(newSelection) => setSelectedIds(newSelection as string[])}
      />

      {documentPreviewState && (
        <PreviewFileDialog
          url={documentPreviewState.downloadUrl}
          fileName={documentPreviewState.fileName}
          onClose={() => setDocumentPreviewState(undefined)}
        />
      )}
    </Stack>
  );
};

export default FundraisingDocumentsGrid;
