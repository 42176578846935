import { LoadingButton } from "@mui/lab";
import { Box, Button, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { arraysHaveSameItems } from "../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../api/adminApi";
import { InvestorPortalFeature } from "../../../../api/types/investorPortalTypes";
import { useClientContext } from "../../../../context/ClientContext";
import PortalPageSettingsEdit from "./PortalPageSettingsEdit";
import { getInitialState, reducer } from "./state";

interface Props {
  initialEnabledFeatures: InvestorPortalFeature[];
  initialEnabledPreviewOnlyFeatures: InvestorPortalFeature[];
}

const updateInvestorPortalSettings = withErrorHandling(adminApi.updateInvestorPortalSettings);

const PortalPagesSettingsEditor = ({ initialEnabledFeatures, initialEnabledPreviewOnlyFeatures }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(initialEnabledFeatures, initialEnabledPreviewOnlyFeatures)
  );

  const [isSaving, setSaving] = useState(false);

  const [savedFeatures, setSavedFeatures] = useState({
    liveFeatures: initialEnabledFeatures,
    previewFeatures: initialEnabledPreviewOnlyFeatures,
  });

  const { hasPermissions } = useClientContext();
  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);

  const handleSaveClick = async () => {
    setSaving(true);

    const [resp, error] = await updateInvestorPortalSettings({
      enabledFeatures: state.liveFeatures,
      enabledPreviewOnlyFeatures: state.previewFeatures,
    });

    setSaving(false);

    if (error) {
      logError(error, "[PortalPagesSettingsEditor] updateInvestorPortalSettings");
      sendNotificationError("Failed to save settings");
      return;
    }

    setSavedFeatures({
      liveFeatures: resp.settings.enabledFeatures ?? [],
      previewFeatures: resp.settings.enabledPreviewOnlyFeatures ?? [],
    });
  };

  const handleCancelClick = () => {
    dispatch({
      type: "RESET",
      payload: savedFeatures,
    });
  };

  const isDirty =
    !arraysHaveSameItems(savedFeatures.liveFeatures, state.liveFeatures) ||
    !arraysHaveSameItems(savedFeatures.previewFeatures, state.previewFeatures);

  return (
    <Stack py={2.5} spacing={3}>
      <Typography color="text.secondary">You can customize the availability of Investor portal pages.</Typography>

      <Box>
        <FormControlLabel
          disabled={!canEditSettings}
          control={<Switch checked={state.all} onChange={() => dispatch({ type: "TOGGLE_ALL" })} />}
          label={<Typography sx={{ ml: 0.5 }}>All pages live</Typography>}
        />
      </Box>

      <Stack spacing={2} width="100%">
        <Typography variant="subtitle1">Portal Pages</Typography>
        <Stack spacing={1} width="100%">
          <PortalPageSettingsEdit
            title="Dashboard"
            toggleValue={state.values.dashboards}
            onToggleChange={(newValue) => dispatch({ type: "TOGGLE_FIELD", payload: { dashboards: newValue } })}
            toggleDisabled={state.disabled.dashboards || !canEditSettings}
          />
          <PortalPageSettingsEdit
            title="Inbox"
            toggleValue={state.values.inbox}
            onToggleChange={(newValue) => dispatch({ type: "TOGGLE_FIELD", payload: { inbox: newValue } })}
            toggleDisabled={state.disabled.inbox || !canEditSettings}
          />
          <PortalPageSettingsEdit
            title="Portfolio"
            toggleValue={state.values.portfolioCompanies}
            onToggleChange={(newValue) => dispatch({ type: "TOGGLE_FIELD", payload: { portfolioCompanies: newValue } })}
            toggleDisabled={state.disabled.portfolioCompanies || !canEditSettings}
          />
          <PortalPageSettingsEdit
            title="Fundraising"
            toggleValue={state.values.fundraising}
            onToggleChange={(newValue) => dispatch({ type: "TOGGLE_FIELD", payload: { fundraising: newValue } })}
            toggleDisabled={state.disabled.fundraising || !canEditSettings}
          />
        </Stack>
      </Stack>

      <Stack spacing={2} width="100%">
        <Typography variant="subtitle1">Settings</Typography>
        <Stack spacing={1} width="100%">
          <PortalPageSettingsEdit
            title="Investor Details"
            toggleValue={state.values.accountSettingsView}
            onToggleChange={(newValue) =>
              dispatch({ type: "TOGGLE_FIELD", payload: { accountSettingsView: newValue } })
            }
            toggleDisabled={state.disabled.accountSettingsView || !canEditSettings}
            checkboxLabel="Enable self-service with approval"
            checkedValue={state.values.accountSettingsEdit}
            onCheckedChange={(checked) => dispatch({ type: "TOGGLE_FIELD", payload: { accountSettingsEdit: checked } })}
            checkboxDisabled={state.disabled.accountSettingsEdit || !canEditSettings}
          />
          <PortalPageSettingsEdit
            title="Bank Account Details"
            toggleValue={state.values.bankAccountDetailsView}
            onToggleChange={(newValue) =>
              dispatch({ type: "TOGGLE_FIELD", payload: { bankAccountDetailsView: newValue } })
            }
            toggleDisabled={state.disabled.bankAccountDetailsView || !canEditSettings}
            checkboxLabel="Enable self-service with approval"
            checkedValue={state.values.bankAccountDetailsEdit}
            onCheckedChange={(checked) =>
              dispatch({ type: "TOGGLE_FIELD", payload: { bankAccountDetailsEdit: checked } })
            }
            checkboxDisabled={state.disabled.bankAccountDetailsEdit || !canEditSettings}
          />
          <PortalPageSettingsEdit
            title="Members"
            toggleValue={state.values.membership}
            onToggleChange={(newValue) => dispatch({ type: "TOGGLE_FIELD", payload: { membership: newValue } })}
            toggleDisabled={state.disabled.membership || !canEditSettings}
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={1}>
        <LoadingButton
          variant="contained"
          disabled={!canEditSettings || !isDirty}
          loading={isSaving}
          onClick={handleSaveClick}
        >
          Save
        </LoadingButton>
        <Button variant="text" color="secondary" disabled={!isDirty} onClick={handleCancelClick}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default PortalPagesSettingsEditor;
