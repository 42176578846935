import { JSX } from "react";
import { ValidationResult, Validator } from "../../../../shared/utilities/validators";
import { EntityFieldConfiguration, FieldAttribute, TextEntityFieldConfiguration } from "../../../api/types/objectTypes";
import TextValueEdit from "./TextValueEdit";

interface Props {
  value: string;
  fieldId: string;
  fieldConfiguration: EntityFieldConfiguration | undefined;
  fieldAttributes: FieldAttribute[];
  onChange: (value: string, fieldId: string, validationResult: ValidationResult) => void;
  validator?: Validator<string>;
  transformOnChangeFunc?: (value: string) => string;
  prefix?: string;
  postfix?: string;
  defaultHelperText?: string;
  displayValueComponent?: JSX.Element | JSX.Element[] | null;
  displayHoverComponent?: JSX.Element | JSX.Element[] | null;
  textPostfix?: string;
  multiline?: boolean;
  fieldInputId?: string;
}

const EntityTextFieldComponent = (props: Props) => {
  const { value, fieldId, fieldConfiguration, fieldAttributes, onChange } = props;

  return (
    <TextValueEdit
      {...props}
      value={value}
      maxLength={(fieldConfiguration as TextEntityFieldConfiguration)?.maxLength}
      onChange={(value, validationResult) => onChange(value, fieldId, validationResult)}
      fieldAttributes={fieldAttributes}
    />
  );
};

export default EntityTextFieldComponent;
