import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { ObjectClassDefinition } from "../../../api/types/objectTypes";
import AddInternalUserAccessDialog from "./AddInternalUserAccessDialog";
import EditInternalUserAccessDrawer from "./EditInternalUserAccessDrawer";
import RemoveInternalUserAccessDialog from "./RemoveInternalUserAccessDialog";
import UserAccessMatrix from "./UserAccessMatrix";
import {
  mapObjectInternalUserPermissionsToUserAccess,
  User,
  UserAccess,
  UserAccessMatrixRow,
} from "./userAccessMatrixModel";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  hasPermissionsToManage: boolean;
}

interface DialogState {
  openDialog?: "add" | "edit" | "remove";
  editedRows?: UserAccessMatrixRow[];
}

const InternalUserAccessMatrix = ({ objectId, objectDefinition, hasPermissionsToManage }: Props) => {
  const [accessItems, setAccessItems] = useState<UserAccess[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [dialogState, setDialogState] = useState<DialogState>({});

  const getObjectInternalUserPermissions = useCallback(
    () => adminApi.getObjectInternalUserPermissions(objectDefinition.objectType, objectId),
    [objectDefinition.objectType, objectId]
  );

  const [objectInternalUserPermissions, fetchPermissionsError] = useFetch(getObjectInternalUserPermissions, (data) => {
    setAccessItems(data.map(mapObjectInternalUserPermissionsToUserAccess));
  });

  const getAllObjectInternalUsers = useCallback(
    () => adminApi.getObjectInternalUsers(objectDefinition.objectType),
    [objectDefinition.objectType]
  );

  const [allObjectInternalUsers, fetchAllUsersError] = useFetch(getAllObjectInternalUsers, (data) => {
    setAllUsers(data);
  });

  const handleAddUsers = useCallback(() => {
    setDialogState({ openDialog: "add" });
  }, []);

  const handleEditUsers = useCallback((editedRows: UserAccessMatrixRow[]) => {
    setDialogState({ openDialog: "edit", editedRows });
  }, []);

  const handleRemoveUsers = useCallback((editedRows: UserAccessMatrixRow[]) => {
    setDialogState({ openDialog: "remove", editedRows });
  }, []);

  const fetchError = fetchPermissionsError || fetchAllUsersError;

  if (fetchError) {
    logError(fetchError, "[InternalUserAccessMatrix]");
    return <DataLoadingFailed title="Could not load internal users" />;
  }

  return (
    <>
      <UserAccessMatrix
        categories={objectDefinition.supportedInternalUserPermissionCategories}
        hasPermissionsToManage={hasPermissionsToManage}
        accessItems={accessItems}
        allUsers={allUsers}
        onAddUsers={handleAddUsers}
        onEditUsers={handleEditUsers}
        onRemoveUsers={handleRemoveUsers}
        isLoading={objectInternalUserPermissions === undefined || allObjectInternalUsers === undefined}
        excludeColumns={["role"]}
      />

      {dialogState.openDialog === "add" && (
        <AddInternalUserAccessDialog
          objectId={objectId}
          objectDefinition={objectDefinition}
          currentAccessItems={accessItems}
          allUsers={allUsers}
          onClose={() => setDialogState({})}
          onSave={setAccessItems}
        />
      )}

      {dialogState.openDialog === "edit" && dialogState.editedRows?.length && (
        <EditInternalUserAccessDrawer
          objectId={objectId}
          objectDefinition={objectDefinition}
          currentAccessItems={accessItems}
          editedRows={dialogState.editedRows}
          onClose={() => setDialogState({})}
          onSave={setAccessItems}
        />
      )}

      {dialogState.openDialog === "remove" && dialogState.editedRows?.length && (
        <RemoveInternalUserAccessDialog
          objectId={objectId}
          objectType={objectDefinition.objectType}
          removedRows={dialogState.editedRows}
          currentAccessItems={accessItems}
          onClose={() => setDialogState({})}
          onSave={setAccessItems}
        />
      )}
    </>
  );
};

export default InternalUserAccessMatrix;
