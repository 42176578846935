import { PropsWithChildren, createContext, useContext } from "react";
import { FormCellValue, MetricExtensionsService } from "../../../api/dataCollectionTypes";
import { DictionaryItem, EntityOptionFieldType } from "../../../api/dictionaryTypes";
import { DataSubmissionBlockContent } from "../../../api/inputFormTypes";
import { defined } from "../../../utilities/typeHelper";

interface Props {
  recipientObjectId: string;
  isSubmissionEditable: boolean;
  globalDictionaries: Partial<Record<EntityOptionFieldType, DictionaryItem[]>>;
  getBlockContent: (blockId: string) => DataSubmissionBlockContent | undefined;
  metricExtensionsService: MetricExtensionsService;
  onBlockCellValueEdit: (blockId: string, formCellValue: FormCellValue) => void;
}

const DataSubmissionFormContext = createContext<Props | undefined>(undefined);

export const DataSubmissionFormContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DataSubmissionFormContext.Provider value={contextValue}>{children}</DataSubmissionFormContext.Provider>
);

export const useDataSubmissionFormContext = () =>
  defined(useContext(DataSubmissionFormContext), "Attempt to use DataSubmissionFormContext without provider");
