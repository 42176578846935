import { SvgIconComponent } from "@mui/icons-material";
import { IconButton, IconButtonProps, SvgIconProps } from "@mui/material";
import { JSX } from "react";

interface Props extends IconButtonProps {
  expanded: boolean;
  onToggle: () => void;
  ExpandIcon: SvgIconComponent | ((props: SvgIconProps) => JSX.Element);
  CollapseIcon: SvgIconComponent | ((props: SvgIconProps) => JSX.Element);
}

const ExpandCollapseButton = ({ expanded, onToggle, ExpandIcon, CollapseIcon, ...props }: Props) => {
  const handleClick: React.MouseEventHandler = (e) => {
    onToggle();
    e.stopPropagation();
  };

  return (
    <IconButton onClick={handleClick} {...props}>
      {expanded && <CollapseIcon />}
      {!expanded && <ExpandIcon />}
    </IconButton>
  );
};

export default ExpandCollapseButton;
