import { Stack } from "@mui/material";
import { useState } from "react";
import { getErrorMessage } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import crmConnectorsApi, { CrmSyncSettings } from "../../../api/crmConnectorsApi";
import { useClientContext } from "../../../context/ClientContext";
import SalesforceLogo from "../../../images/salesforce.png";
import IntegrationHeader from "../integration/IntegrationHeader";
import CrmActions from "./CrmActions";
import { CrmConnectorContextProvider } from "./CrmConectorContext";
import CrmManagement from "./CrmManagement";
import CrmSetupInfo from "./CrmSetupInfo";
import FullResyncInfo from "./FullResyncInfo";
import TestConnectionInfo from "./TestConnectionInfo";
import {
  FullResyncState,
  getInitialFullResyncState,
  getInitialTestConnectionState,
  TestConnectionState,
} from "./crmConnectorState";

const promotionText =
  "You can use REST API tools to create, manipulate, and search data in Salesforce by sending HTTP requests to endpoints in Salesforce. Depending on where you send requests, you access and operate on different pieces of information, called resources. Resources include records, query results, metadata, and more.";

const SalesforceConnectorPage = () => {
  const { salesforceApp } = useClientContext();

  const [connectionInfo, fetchConnectionError] = useFetch(crmConnectorsApi.salesforce.getConnection);

  const [connectorSettings, fetchSettingsError, { setData: setConnectorSettings }] = useFetch(
    crmConnectorsApi.salesforce.getConnectorSettings
  );

  const [testConnectionState, setTestConnectionState] = useState<TestConnectionState>(getInitialTestConnectionState());
  const [fullResyncState, setFullResyncState] = useState<FullResyncState>(getInitialFullResyncState());

  const fetchError = fetchConnectionError || fetchSettingsError;
  if (fetchError) {
    logError(fetchError, "[SalesforceConnectorPage] getConnection");
    return <DataLoadingFailed title="Loading connector settings failed" text={getErrorMessage(fetchError)} />;
  }

  const loading = connectionInfo === undefined || connectorSettings === undefined;
  if (loading) {
    return <InlineLoader />;
  }

  const handleSyncEnabledUpdate = (syncEnabled: boolean) => {
    setConnectorSettings({ ...connectorSettings, syncEnabled });
  };

  const handleSyncSettingsUpdate = (syncSettings: CrmSyncSettings) => {
    setConnectorSettings({ ...connectorSettings, syncSettings });
  };

  return (
    <CrmConnectorContextProvider
      crmName="Salesforce"
      crmApiName="salesforce"
      logoSrc={SalesforceLogo}
      connectionInfo={connectionInfo}
      syncEnabled={connectorSettings.syncEnabled}
      onSyncEnabledUpdate={handleSyncEnabledUpdate}
      syncSettings={connectorSettings.syncSettings}
      onSyncSettingsUpdate={handleSyncSettingsUpdate}
      testConnectionState={testConnectionState}
      onTestConnectionStateUpdate={setTestConnectionState}
      fullResyncState={fullResyncState}
      onFullResyncStateUpdate={setFullResyncState}
    >
      <IntegrationHeader
        title="Salesforce"
        logoSrc={SalesforceLogo}
        text={promotionText}
        appStatus={salesforceApp?.status}
      />
      <CrmSetupInfo />
      <CrmActions />
      <Stack spacing={2} mt={3} ml={3} width="48rem">
        <TestConnectionInfo />
        <FullResyncInfo />
      </Stack>
      <CrmManagement />
    </CrmConnectorContextProvider>
  );
};

export default SalesforceConnectorPage;
