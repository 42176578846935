import React, { PropsWithChildren, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import { CrmConnectionInfo, CrmId, CrmSyncSettings } from "../../../api/crmConnectorsApi";
import { FullResyncState, TestConnectionState } from "./crmConnectorState";

interface CrmConnectorContextProps {
  crmName: string;
  crmApiName: CrmId;
  logoSrc: string;
  connectionInfo: CrmConnectionInfo;
  syncEnabled: boolean;
  onSyncEnabledUpdate?: (syncEnabled: boolean) => void;
  syncSettings?: CrmSyncSettings;
  onSyncSettingsUpdate?: (syncSettings: CrmSyncSettings) => void;
  testConnectionState: TestConnectionState;
  onTestConnectionStateUpdate: (state: TestConnectionState) => void;
  fullResyncState: FullResyncState;
  onFullResyncStateUpdate: (state: FullResyncState) => void;
}

const CrmConnectorContext = React.createContext<CrmConnectorContextProps | undefined>(undefined);

export const CrmConnectorContextProvider = ({ children, ...val }: PropsWithChildren<CrmConnectorContextProps>) => (
  <CrmConnectorContext.Provider value={val}>{children}</CrmConnectorContext.Provider>
);

export const useCrmConnectorContext = () => {
  const contextValue = useContext(CrmConnectorContext);
  return defined(contextValue);
};
