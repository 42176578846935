export interface TestConnectionState {
  status: "hidden" | "loading" | "success" | "error";
  errorMessage?: string;
}

export interface FullResyncState {
  status: "hidden" | "starting" | "success" | "error";
  errorMessage?: string;
}

export const getInitialTestConnectionState = (): TestConnectionState => ({ status: "hidden" });

export const getInitialFullResyncState = (): FullResyncState => ({ status: "hidden" });
