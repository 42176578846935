import { TextField } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { FocusEvent, forwardRef, useRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Maybe } from "../../../../../../../shared/types";
import { getLocalNumberFormatConfig } from "../../../../../../../shared/utilities/formatters";
import { InvoiceDetailsLine } from "../invoiceDetailsState";

interface Props {
  params: GridRenderCellParams<InvoiceDetailsLine, number | undefined>;
}

const formattingConfig = getLocalNumberFormatConfig();

const InvoiceLineAmount = forwardRef<NumericFormatProps, Props>((props, ref) => {
  const { id, value, field } = props.params;

  const apiRef = useGridApiContext();
  const currentValueRef = useRef<number | null>();
  currentValueRef.current = value === undefined ? null : value;

  const handleChange = (newValue: Maybe<number>) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue ?? undefined });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleChange(currentValueRef.current);
    }
  };

  return (
    <NumericFormat
      value={currentValueRef.current}
      getInputRef={ref}
      onValueChange={(values) => (currentValueRef.current = values.floatValue)}
      onBlur={() => handleChange(currentValueRef.current)}
      thousandSeparator={formattingConfig.groupSeparator}
      decimalSeparator={formattingConfig.decimalSeparator}
      allowNegative={true}
      decimalScale={2}
      fixedDecimalScale
      slotProps={{
        input: {
          inputProps: { style: { textAlign: "right" }, onKeyDown: handleKeyDown },
          onFocus: (event: FocusEvent<HTMLInputElement>) => event.target.select(),
        },
      }}
      autoFocus
      customInput={TextField}
    />
  );
});

InvoiceLineAmount.displayName = "InvoiceLineAmount";

export default InvoiceLineAmount;
