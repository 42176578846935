import SaveIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import { Box, Card, CardMedia, IconButton, Stack } from "@mui/material";
import print from "print-js";
import { useState } from "react";
import { ApiResponse, FileDownloadInfo } from "../../../../shared/api/types";
import { logError } from "../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../shared/services/downloadFile";

interface Props {
  fileInfo: FileDownloadInfo;
  // Original fileInfo is not enough: the downloadUrl might expire before user attempts to download / print the file
  getFileDownloadInfoCallback: () => Promise<ApiResponse<FileDownloadInfo>>;
}

const ImagePreview = ({ fileInfo, getFileDownloadInfoCallback }: Props) => {
  const [isOperationInProgress, setOperationInProgress] = useState(false);

  const handlePrintClick = async () => {
    setOperationInProgress(true);
    const resp = await getFileDownloadInfoCallback();
    if (resp.data !== undefined) {
      print({ printable: resp.data.downloadUrl, type: "image", documentTitle: resp.data.fileName });
    } else {
      logError(resp.error, "[ImagePreview] handleDownloadClick");
    }

    setOperationInProgress(false);
  };

  const handleDownloadClick = async () => {
    setOperationInProgress(true);
    const resp = await getFileDownloadInfoCallback();
    if (resp.data !== undefined) {
      downloadFileFromUrl(resp.data.downloadUrl);
    } else {
      logError(resp.error, "[ImagePreview] handleDownloadClick");
    }
    setOperationInProgress(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" py={1} px={2.5}>
        <Stack direction="row" alignItems="center">
          <IconButton onClick={handlePrintClick} disabled={isOperationInProgress}>
            <PrintIcon />
          </IconButton>
          <IconButton onClick={handleDownloadClick} disabled={isOperationInProgress}>
            <SaveIcon />
          </IconButton>
        </Stack>
      </Box>
      <Box display="flex" flexDirection="column" height="100%" pb={2}>
        <Card sx={{ flexGrow: 1 }} variant="outlined">
          <CardMedia
            component="img"
            src={fileInfo.downloadUrl}
            sx={{ objectFit: "contain", border: 0, height: "100%" }}
          />
        </Card>
      </Box>
    </>
  );
};

export default ImagePreview;
