import { Alert } from "@mui/material";
import { useCrmConnectorContext } from "./CrmConectorContext";
import { TestConnectionState } from "./crmConnectorState";

const AlertMessage = ({ status, errorMessage }: TestConnectionState) => {
  if (status === "success") {
    return (
      <>
        Test connection was successful. All logs you can see in tab <strong>Logs</strong>.
      </>
    );
  }

  let msg = "Test connection failed";
  if (errorMessage) {
    msg += `: ${errorMessage}`;
  }

  return <>{msg}</>;
};

const TestConnectionInfo = () => {
  const { testConnectionState, onTestConnectionStateUpdate } = useCrmConnectorContext();

  const { status, errorMessage } = testConnectionState;

  if (status === "hidden" || status === "loading") {
    return null;
  }

  return (
    <Alert severity={status} onClose={() => onTestConnectionStateUpdate({ status: "hidden" })}>
      <AlertMessage status={status} errorMessage={errorMessage} />
    </Alert>
  );
};

export default TestConnectionInfo;
