import { useState } from "react";
import { MetricTableBlockRow, TableBlockColumn } from "../../../../api/inputFormTypes";
import { convertToNumber } from "../../../../utilities/numberHelper";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import MetricMoneyField from "../fields/MetricMoneyField";
import MetricNumberField from "../fields/MetricNumberField";
import MetricSelectField from "../fields/MetricSelectField";
import MetricTextField from "../fields/MetricTextField";
import MultilineMetricTextField from "../fields/MultilineMetricTextField";
import TrafficLightSelectField, { TrafficLightValue } from "../fields/TrafficLightSelectField";

interface Props {
  row: MetricTableBlockRow;
  columnDefinition: TableBlockColumn;
  currencyCode: string;
  onValueChange: (rowId: string, columnId: string, value: string | number) => void;
}

const trafficLightToNumberMap: Record<TrafficLightValue, number> = {
  green: 1,
  yellow: 2,
  red: 3,
};

const numberToTrafficLightMap: Record<number, TrafficLightValue> = Object.fromEntries(
  Object.entries(trafficLightToNumberMap).map(([key, value]) => [value, key as TrafficLightValue])
);

const TableFormCellEditor = ({ row, columnDefinition, currencyCode, onValueChange }: Props) => {
  const { isSubmissionEditable } = useDataSubmissionFormContext();

  const [cellValue, setCellValue] = useState<string | number | undefined>(row.values[columnDefinition.id]);

  const handleValueChange = (rowId: string, columnId: string, value: string | number | undefined) => {
    setCellValue(value);
    onValueChange(rowId, columnId, value ?? 0);
  };

  if (row.type !== "Metric") {
    return null;
  }

  const readOnly = !columnDefinition.isEditable;
  const disabled = !isSubmissionEditable || readOnly;

  if (row.dataType === "Select" && row.valueSource) {
    return (
      <MetricSelectField
        value={cellValue?.toString()}
        onChange={(value) => handleValueChange(row.id, columnDefinition.id, value)}
        valueSource={row.valueSource}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  if (row.dataType === "Text") {
    return (
      <MultilineMetricTextField
        value={cellValue?.toString() ?? ""}
        onChange={(value) => handleValueChange(row.id, columnDefinition.id, value)}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  if (row.dataType === "Number" && row.inputComponent === "TrafficLightSelect") {
    const numericValue = convertToNumber(cellValue);
    const trafficLightValue = numericValue ? numberToTrafficLightMap[numericValue] : undefined;
    return (
      <TrafficLightSelectField
        value={trafficLightValue}
        onChange={(value) => handleValueChange(row.id, columnDefinition.id, trafficLightToNumberMap[value])}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  if (row.dataType === "Number") {
    const numericValue = convertToNumber(cellValue);
    return (
      <MetricNumberField
        value={numericValue}
        onChange={(value) => handleValueChange(row.id, columnDefinition.id, value)}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  if (row.dataType === "Money") {
    const numericValue = convertToNumber(cellValue);
    return (
      <MetricMoneyField
        value={numericValue}
        onChange={(value) => handleValueChange(row.id, columnDefinition.id, value)}
        currencyCode={currencyCode}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  return (
    <MetricTextField
      value={cellValue?.toString() ?? ""}
      onChange={(value) => handleValueChange(row.id, columnDefinition.id, value)}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

export default TableFormCellEditor;
