import { useCallback } from "react";
import { formatMoney } from "../../../../utilities/formatters";
import NumberTextField from "../../../NumberTextField";

interface Props {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  currencyCode: string;
  readOnly?: boolean;
  disabled?: boolean;
}

const MetricMoneyField = ({ value, onChange, currencyCode, readOnly, disabled }: Props) => {
  const valueFormatter = useCallback(
    (value: number) => formatMoney(value, currencyCode, { maximumFractionDigits: 0 }),
    [currencyCode]
  );

  return (
    <NumberTextField
      fullWidth
      disabled={disabled}
      numberValue={value}
      onNumberChange={onChange}
      valueFormatter={valueFormatter}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
    />
  );
};

export default MetricMoneyField;
