import RemoveIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Button } from "@mui/material";
import CustomDataGridHeaderToolbar from "../../common/grid/CustomDataGridHeaderToolbar";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  onEditSelectedUsers: (ids: string[]) => void;
  onRemoveSelectedUsers: (ids: string[]) => void;
}

const UserAccessMatrixGridToolbar = ({
  selectedIds,
  setSelectedIds,
  onEditSelectedUsers,
  onRemoveSelectedUsers,
}: Props) => {
  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button
        color="secondary"
        variant="text"
        startIcon={<EditIcon />}
        onClick={() => onEditSelectedUsers(selectedIds)}
      >
        Bulk Edit
      </Button>
      <Button
        color="error"
        variant="text"
        startIcon={<RemoveIcon />}
        onClick={() => onRemoveSelectedUsers(selectedIds)}
      >
        Remove
      </Button>
    </CustomDataGridHeaderToolbar>
  );
};

export default UserAccessMatrixGridToolbar;
