import { TabPanel } from "@mui/lab";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router";
import { parseSearchParamWithFallback } from "../../../../../shared/utilities/searchParamsHelper";
import { useClientContext } from "../../../../context/ClientContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import AllDataSubmissionsList from "./AllDataSubmissionsList";
import MyDataSubmissionsList from "./MyDataSubmissionsList";

const tabValues = ["all", "my"] as const;
type TabValue = (typeof tabValues)[number];

const tabPanelSx = { flex: 1, px: 3, pt: 2.5, width: "100%" };

const DataSubmissionsPage = () => {
  const { hasAnyPermission } = useClientContext();

  const hasAccessToPortfolioMonitoringManagement = useMemo(
    () => hasAnyPermission(["ViewPortfolioMonitoring", "ManagePortfolioMonitoring"]),
    [hasAnyPermission]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TabValue>(
    parseSearchParamWithFallback(
      searchParams,
      "tab",
      tabValues,
      hasAccessToPortfolioMonitoringManagement ? "all" : "my"
    )
  );

  const handleTabChange = (tab: TabValue) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <GeneralPageHeader title="Data Submissions" hideBorderLine />

      <PageTabs
        tabs={hasAccessToPortfolioMonitoringManagement ? tabValues : ["my"]}
        labels={hasAccessToPortfolioMonitoringManagement ? ["All Submissions", "My Submissions"] : ["My Submissions"]}
        value={selectedTab}
        onChange={handleTabChange}
      >
        {hasAccessToPortfolioMonitoringManagement && (
          <TabPanel value="all" sx={tabPanelSx}>
            <AllDataSubmissionsList />
          </TabPanel>
        )}
        <TabPanel value="my" sx={tabPanelSx}>
          <MyDataSubmissionsList />
        </TabPanel>
      </PageTabs>
    </>
  );
};

export default DataSubmissionsPage;
