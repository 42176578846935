import { GridColDef } from "@mui/x-data-grid-premium";
import { ReportingPeriod } from "../../../../../shared/api/dataCollectionTypes";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import { stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { reportingPeriodCaptionsMap } from "../../../../../shared/utilities/enumCaptions";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";

export const columnDefinitions: GridColDef<DataCollectionRequestTemplateVersion>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    minWidth: 160,
  },
  {
    field: "reportingPeriod",
    headerName: "Period",
    flex: 1,
    minWidth: 160,
    valueFormatter: (value: ReportingPeriod) => (value ? reportingPeriodCaptionsMap[value] : ""),
  },
  {
    field: "scenarioName",
    headerName: "Scenario",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "recipientObjectIds",
    headerName: "Companies",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 80,
    valueFormatter: (value: string[] | undefined) => (value?.length ?? 0).toLocaleString(),
  },
  {
    field: "categories",
    headerName: "Categories",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => {
      const allCategories = [...row.contactCategories, ...row.internalUserPermissionCategories];
      return allCategories.length === 0 ? (
        "-"
      ) : (
        <InlineItemsList
          displayCount={2}
          items={allCategories}
          propGetter={(x) => x}
          sortComparer={stringComparerBy((x) => x)}
        />
      );
    },
  },
  {
    field: "actionsPlaceholder",
    headerName: "",
    width: 80,
  },
];
