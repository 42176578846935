import { addMinutes, format, formatDistanceToNow, isValid, isWithinInterval, parseISO, subMinutes } from "date-fns";
import { Maybe } from "../types";

const isValidDate = (date: Date) => isValid(date) && date.getFullYear() > 1;

/**
 * @param date Date object to format
 * @param fmt (optional) See https://date-fns.org/v2.30.0/docs/format
 * @returns formatted date, by default like "Apr 29, 1453"
 */
export const formatDate = (date: Date, fmt = "PP"): string => (isValidDate(date) ? format(date, fmt) : "");

/**
 * @param isoDateString: ISO Date string, e.g. "1453-04-29T00:00:00.000Z"
 * @param fmt (optional) See https://date-fns.org/v2.30.0/docs/format
 * @returns formatted date, by default like "Apr 29, 1453"
 */
export const convertISODate = (isoDateString: Maybe<string>, fmt = "PP"): string =>
  isoDateString ? formatDate(parseISO(isoDateString), fmt) : "";

/**
 * @returns formatted date like "04/29/1453"
 */
export const formatDateShort = (date: Date) => formatDate(date, "P");

/**
 * @returns formatted date like "04/29/1453"
 */
export const convertISODateShort = (isoDateString: Maybe<string>) => convertISODate(isoDateString, "P");

/**
 * @returns formatted date like "1453-04-29"
 */
export const formatDateOnly = (date: Date) => formatDate(date, "yyyy-MM-dd");

/**
 * @returns formatted date like "1453-04-29"
 */
export const convertISODateOnly = (isoDateString: Maybe<string>) => convertISODate(isoDateString, "yyyy-MM-dd");

/**
 * @returns formatted date and time like "Apr 29, 1453, 12:00 AM"
 */
export const formatDateTime = (date: Date) => formatDate(date, "PPp");

/**
 * @returns formatted date and time like "Apr 29, 1453, 12:00 AM"
 */
export const convertISODateTime = (isoDateString: Maybe<string>) => convertISODate(isoDateString, "PPp");

/**
 * @returns formatted date and time like "04/29/1453, 12:00 AM"
 */
export const formatDateTimeShort = (date: Date) => formatDate(date, "Pp");

/**
 * @returns formatted date and time like "04/29/1453, 12:00 AM"
 */
export const convertISODateTimeShort = (isoDateString: Maybe<string>) => convertISODate(isoDateString, "Pp");

/**
 * @returns formatted date and time like "Apr 29, 1453, 12:00:00 AM"
 */
export const formatDateTimeWithSeconds = (date: Date) => formatDate(date, "PPpp");

/**
 * @returns formatted date and time like "Apr 29, 1453, 12:00:00 AM"
 */
export const convertISODateTimeWithSeconds = (isoDateString: Maybe<string>) => convertISODate(isoDateString, "PPpp");

/**
 * @returns formatted date and time like "04/29/1453, 12:00:00 AM"
 */
export const formatDateTimeShortWithSeconds = (date: Date) => formatDate(date, "Ppp");

/**
 * @returns formatted date and time like "04/29/1453, 12:00:00 AM"
 */
export const convertISODateTimeShortWithSeconds = (isoDateString: Maybe<string>) =>
  convertISODate(isoDateString, "Ppp");

/**
 * @returns humanized date and time like "17 minutes ago" if the date is within the threshold
 */
export const formatDateTimeHumanized = (
  date: Date,
  { thresholdMinutes }: { thresholdMinutes: number },
  defaultFormatter?: (d: Date) => string
) => {
  if (!isValidDate(date)) {
    return "";
  }

  if (
    isWithinInterval(date, {
      start: subMinutes(new Date(), thresholdMinutes),
      end: addMinutes(new Date(), thresholdMinutes),
    })
  ) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  return (defaultFormatter ?? formatDateTime)(date);
};

/**
 * @returns humanized date and time like "17 minutes ago" if the date is within the threshold
 */
export const convertISODateTimeHumanized = (
  isoDateString: Maybe<string>,
  { thresholdMinutes }: { thresholdMinutes: number },
  defaultFormatter?: (d: Date) => string
) => (isoDateString ? formatDateTimeHumanized(parseISO(isoDateString), { thresholdMinutes }, defaultFormatter) : "");

export const quarterNumber = (date: Date): number => Math.floor(date.getMonth() / 3) + 1;

export const pastQuarterLastDay = (date?: Date): Date => {
  const calculatingDate = date || new Date();
  const quarter = quarterNumber(calculatingDate);
  return quarter === 1
    ? new Date(calculatingDate.getFullYear() - 1, 11, 31)
    : new Date(calculatingDate.getFullYear(), (quarter - 1) * 3, 0);
};
