import { Avatar, Icon, Stack, Typography } from "@mui/material";
import InlineItemsList from "../../../../../../shared/components/InlineItemsList";
import ApiGatewayIcon from "../../../../../icons/ApiGatewayIcon";

type AccessibleCompany = { title: string; icon?: string; code: string };

interface Props {
  accessibleCompanies: AccessibleCompany[];
}

const DeveloperPortal = ({ accessibleCompanies }: Props) => {
  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon sx={{ height: 24, width: 24 }}>
          <ApiGatewayIcon fontSize="medium" />
        </Icon>
        <Typography>API Gateway</Typography>
      </Stack>
      <Stack spacing={1} alignItems="center">
        <InlineItemsList<AccessibleCompany>
          displayCount={1}
          items={accessibleCompanies}
          propGetter={(prop) => prop.title}
          justify="flex-start"
          listItemPropGetter={(prop) => (
            <Stack key={prop.code} direction="row" spacing={1} alignItems="center">
              <Avatar
                src={prop.icon}
                variant="rounded"
                alt="Company Logo"
                sx={{ width: "20px", height: "20px" }}
                slotProps={{
                  img: { style: { maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" } },
                }}
              />
              <Typography>{prop.title}</Typography>
            </Stack>
          )}
        />
      </Stack>
    </Stack>
  );
};

export default DeveloperPortal;
