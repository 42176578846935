import { Collapse, Divider, Grid2, Paper, Stack, Typography } from "@mui/material";
import { JSX, PropsWithChildren, useState } from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import { ExpandCollapseButton } from "../../../../../../shared/reporting/components/reports/ExpandCollapseButton";
import { SelectedCompany } from "./hooks/SelectedCompany";

export interface CompanyItemProps {
  company: SelectedCompany;
  companyBranding: ListItemData | undefined;
  Action?: JSX.Element;
  Summary?: JSX.Element;
}

export function CompanyMultipleReportsListItem({
  company,
  companyBranding,
  children,
  Action,
  Summary,
}: PropsWithChildren<CompanyItemProps>) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Paper variant="outlined" sx={(theme) => ({ borderColor: theme.palette.divider })}>
      <Stack>
        <Stack direction="row" sx={{ pr: 1, alignItems: "center" }}>
          <Grid2
            container
            sx={{
              pr: 1,
              pl: 2,
              pt: 1.5,
              spacing: 1.5,
              alignItems: "center",
              direction: "row",
              width: "100%",
            }}
          >
            <Stack direction="row" spacing={1.5} alignItems="center" flexGrow={1}>
              <img src={companyBranding?.iconSrc} alt="" width={24} height={24} />
              <Typography variant="subtitle1">{companyBranding?.label}</Typography>
            </Stack>
            {Action}
          </Grid2>
        </Stack>
        <Grid2 container width="100%">
          <Grid2 container sx={{ flex: 1, flexDirection: "column", width: "100%" }}>
            <Stack direction="row" alignItems="center" flexGrow={1} sx={{ pl: 1.5, pr: 2, pb: 0.5 }}>
              <ExpandCollapseButton expanded={expanded} onClick={setExpanded} />
              <Typography sx={{ color: "text.primary" }}>{company.reports.length} Reports</Typography>
              <HorizontalFill />
              {Summary}
            </Stack>
            <Collapse in={expanded}>
              <Divider />
              <Grid2
                container
                sx={{
                  flexDirection: "column",
                  gap: 1,
                  p: 2,
                  bgcolor: "background.grey",
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  width: "100%",
                }}
              >
                {children}
              </Grid2>
            </Collapse>
          </Grid2>
        </Grid2>
      </Stack>
    </Paper>
  );
}
