import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { CrmSyncLogs } from "../../../../api/crmConnectorsApi";
import LogDetailsDialog from "../../../common/syncLogs/LogDetailsDialog";
import LogStatusIcon from "../../../common/syncLogs/LogStatusIcon";
import { LogsTableRowData, getRows } from "./logsTableDataProvider";

interface Props {
  logItems: CrmSyncLogs[];
  excludeColumns: string[];
}

interface LogsTableRowProps extends LogsTableRowData {
  onView: (details: CrmSyncLogs) => void;
  excludeColumns: string[];
}

const LogsTableHeadRow = ({ excludeColumns }: { excludeColumns: string[] }) => (
  <TableRow>
    {!excludeColumns.includes("status") && <TableCell>Status</TableCell>}
    {!excludeColumns.includes("completed") && <TableCell>Completed</TableCell>}
    {!excludeColumns.includes("contacts") && <TableCell align="right">Contacts</TableCell>}
    {!excludeColumns.includes("investors") && <TableCell align="right">Investors</TableCell>}
    <TableCell></TableCell>
  </TableRow>
);

const LogsTableRow = ({
  severity,
  completed,
  contacts,
  investors,
  details,
  excludeColumns,
  onView,
}: LogsTableRowProps) => {
  const handleViewClick = () => onView(details);

  return (
    <TableRow>
      {!excludeColumns.includes("status") && (
        <TableCell width="10%">
          <LogStatusIcon severity={severity} />
        </TableCell>
      )}
      {!excludeColumns.includes("completed") && <TableCell>{completed}</TableCell>}
      {!excludeColumns.includes("contacts") && <TableCell align="right">{contacts}</TableCell>}
      {!excludeColumns.includes("investors") && <TableCell align="right">{investors}</TableCell>}
      <TableCell align="right" width="15%" padding="checkbox">
        <Button variant="text" color="primary" onClick={handleViewClick}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

const LogsTable = ({ logItems, excludeColumns }: Props) => {
  const [logDetails, setLogDetails] = useState<CrmSyncLogs>();
  const [showLogDetails, setShowLogDetails] = useState(false);

  const handleViewDetails = (logDetails: CrmSyncLogs) => {
    setLogDetails(logDetails);
    setShowLogDetails(true);
  };

  const handleLogDetailsClose = () => setShowLogDetails(false);

  const rows = getRows(logItems);

  return (
    <>
      <TableContainer sx={(theme) => ({ maxHeight: theme.spacing(70) })}>
        <Table stickyHeader>
          <TableHead>
            <LogsTableHeadRow excludeColumns={excludeColumns} />
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <LogsTableRow {...row} key={row.key} onView={handleViewDetails} excludeColumns={excludeColumns} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LogDetailsDialog open={showLogDetails} onClose={handleLogDetailsClose} logDetails={logDetails} />
    </>
  );
};

export default LogsTable;
