import AddIcon from "@mui/icons-material/AddRounded";
import {
  Autocomplete,
  AutocompleteProps,
  Button,
  Divider,
  List,
  ListItem,
  SlotCommonProps,
  Typography,
} from "@mui/material";
import { forwardRef, HTMLAttributes } from "react";
import useLazyRenderList from "../hooks/useLazyRenderList";

interface Props<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo> {
  withLazyRendering?: boolean;
  noItemsText?: string;
  onCreateOption?: () => void;
}

const NEW_OPTION_BUTTON_ID = "new_option_button";

const NewOptionButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Button sx={{ ml: 2, my: 1 }} startIcon={<AddIcon />} variant="text" id={NEW_OPTION_BUTTON_ID} onClick={onClick}>
      Create New
    </Button>
  );
};

const Listbox = forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props;

  return (
    <List role="listbox" ref={ref} {...other}>
      {children}
    </List>
  );
});

Listbox.displayName = "Listbox";

const ListboxLazyRender = forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props;
  const { loadMore, loadedItems } = useLazyRenderList({ children });

  return (
    <List role="listbox" onScroll={loadMore} ref={ref} {...other}>
      {loadedItems}
    </List>
  );
});

ListboxLazyRender.displayName = "ListboxLazyRender";

const ListboxWithNewOption = forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props;

  return (
    <>
      <List role="listbox" ref={ref} {...other}>
        {children}
      </List>
      <Divider />
      <NewOptionButton />
    </>
  );
});

ListboxWithNewOption.displayName = "ListboxWithNewOption";

const ListboxLazyRenderWithNewOption = forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props;
  const { loadMore, loadedItems } = useLazyRenderList({ children });

  return (
    <>
      <List role="listbox" onScroll={loadMore} ref={ref} {...other}>
        {loadedItems}
      </List>
      <Divider />
      <NewOptionButton />
    </>
  );
});

ListboxLazyRenderWithNewOption.displayName = "ListboxLazyRenderWithNewOption";

const AutocompleteCreatable = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  withLazyRendering,
  noItemsText,
  onCreateOption,
  ...props
}: Props<Value, Multiple, DisableClearable, FreeSolo>) => {
  const listboxComponent =
    onCreateOption !== undefined
      ? withLazyRendering
        ? ListboxLazyRenderWithNewOption
        : ListboxWithNewOption
      : withLazyRendering
        ? ListboxLazyRender
        : Listbox;

  return (
    <Autocomplete
      {...props}
      slotProps={{
        ...props.slotProps,
        listbox: { component: listboxComponent },
        paper: {
          ...props.slotProps?.paper,
          sx: { ...(props.slotProps?.paper as SlotCommonProps)?.sx, ".MuiAutocomplete-noOptions": { p: 0 } },
        },
      }}
      onClose={(event, reason) => {
        if ("relatedTarget" in event && (event.relatedTarget as HTMLElement)?.id === NEW_OPTION_BUTTON_ID) {
          onCreateOption?.();
        } else {
          props.onClose?.(event, reason);
        }
      }}
      noOptionsText={
        <>
          <ListItem sx={{ py: 2 }}>
            <Typography>{noItemsText || "No items"}</Typography>
          </ListItem>
          {onCreateOption !== undefined && (
            <>
              <Divider />
              <NewOptionButton onClick={onCreateOption} />
            </>
          )}
        </>
      }
    />
  );
};

export default AutocompleteCreatable;
