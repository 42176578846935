import { Typography } from "@mui/material";
import { MonthlyInternalTableBlockAttributes, MonthlyInternalTableBlockContent } from "../../../../api/inputFormTypes";
import BlockSection from "../BlockSection";
import MonthlyInternalTableInputForm from "./MonthlyInternalTableInputForm";

interface Props {
  blockId: string;
  attributes: MonthlyInternalTableBlockAttributes;
  content: MonthlyInternalTableBlockContent;
}

const MonthlyInternalTableFormBlock = ({ blockId, content }: Props) => {
  return (
    <BlockSection>
      <Typography variant="h6">{content.table.name}</Typography>
      <MonthlyInternalTableInputForm
        blockId={blockId}
        columnDefinitions={content.table.columns}
        rowDefinitions={content.table.rows}
        currencyCode={content.currencyCode}
      />
    </BlockSection>
  );
};

export default MonthlyInternalTableFormBlock;
