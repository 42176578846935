import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Button, Input, Stack, Typography } from "@mui/material";
import { JSX, useRef, useState } from "react";
import { useLocalization } from "../../hooks/useLocalization";
import { formatFileSize } from "../../utilities/fileHelper";
import { formatString } from "../../utilities/stringFormatter";
import { ensureSvgFileHasSize } from "../../utilities/svgFileHelper";

interface Props {
  imageSrc: string | undefined;
  originalImageSrc?: string | undefined;
  allowedImageTypes: string;
  maxFileSizeBytes: number;
  width: number | string;
  height: number | string;
  uploadButtonName: string;
  showUploadButtonIcon?: boolean | undefined;
  resetButtonName?: string | undefined;
  buttonsPosition: "bottom" | "right";
  backgroundSize?: string | undefined;
  backgroundColor?: string | undefined;
  disabled?: boolean;
  border?: "solid" | "dashed" | undefined;
  Placeholder?: JSX.Element | JSX.Element[] | null;
  showPlaceholderOverImage?: boolean | undefined;
  onImageSelected: (file: File | undefined, url: string) => void;
}

const ImageUploaderWithPreview = ({
  imageSrc,
  originalImageSrc,
  allowedImageTypes,
  maxFileSizeBytes,
  width,
  height,
  uploadButtonName,
  showUploadButtonIcon,
  resetButtonName,
  buttonsPosition,
  backgroundSize,
  backgroundColor,
  disabled,
  border,
  Placeholder,
  showPlaceholderOverImage,
  onImageSelected,
}: Props) => {
  const uploader = useLocalization().image_uploader;
  const fileUploadRef = useRef<HTMLElement>(null);

  const [warningMsg, setWarningMsg] = useState("");

  const handleReset = () => {
    onImageSelected(undefined, originalImageSrc || "");
    clearNativeInput();
    setWarningMsg("");
  };

  const clearNativeInput = () => {
    const input = fileUploadRef?.current?.firstChild as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setWarningMsg("");

    const { files } = e.target;
    if (files === null) {
      return;
    }

    let file = files[0];
    if (file === undefined) {
      return;
    }

    if (file.size > maxFileSizeBytes) {
      setWarningMsg(
        formatString(uploader.uploadMaxSizeWarning, formatFileSize(file.size), formatFileSize(maxFileSizeBytes))
      );
      return;
    }

    file = await ensureSvgFileHasSize(file, height, width);
    onImageSelected(file, URL.createObjectURL(file));
  };

  if (fileUploadRef && imageSrc !== originalImageSrc) {
    clearNativeInput();
  }

  const uniqueUploaderId = `image-uploader-${Math.floor(new Date().valueOf() * Math.random())}`;

  return (
    <Stack>
      <Stack
        spacing={2}
        direction={buttonsPosition === "right" ? "row" : "column"}
        alignItems={buttonsPosition === "right" ? "center" : "flex-start"}
      >
        <Box
          position="relative"
          height={height}
          width={width}
          border={border ? `1px ${border} #E2E8F0` : "none"}
          borderRadius="4px"
          sx={{
            backgroundImage: `url(${imageSrc})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: backgroundSize || "contain",
            backgroundColor: backgroundColor || (imageSrc ? "transparent" : "#F6F8FA"),
            backgroundPosition: "center center",
          }}
        >
          {(!imageSrc || !!showPlaceholderOverImage) && Placeholder}
        </Box>
        <Stack direction="row" gap={1}>
          <label htmlFor={uniqueUploaderId}>
            {!disabled && (
              <Input
                ref={fileUploadRef}
                inputProps={{ accept: allowedImageTypes }}
                id={uniqueUploaderId}
                type="file"
                sx={{ display: "none" }}
                onChange={handleImageChange}
              />
            )}
            <Button
              startIcon={showUploadButtonIcon ? <FileUploadIcon /> : undefined}
              variant="outlined"
              component="span"
              color="secondary"
              disabled={disabled}
            >
              {uploadButtonName}
            </Button>
          </label>
          {originalImageSrc && (
            <Button
              variant="text"
              color="secondary"
              disabled={disabled || originalImageSrc === imageSrc}
              onClick={handleReset}
            >
              {resetButtonName || uploader.resetButton}
            </Button>
          )}
        </Stack>
      </Stack>
      {warningMsg && (
        <Typography color="error" mt={1}>
          {warningMsg}
        </Typography>
      )}
    </Stack>
  );
};

export default ImageUploaderWithPreview;
