import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import EmailTextField from "../../../../../shared/components/inputs/EmailTextField";
import PhoneFieldExt from "../../../../../shared/components/inputs/PhoneFieldExt";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";

interface Props {
  settings: SupportInfoSettings;
}

interface SupportInfoSettings {
  supportEmail: string;
  supportPhone: string;
}

const updateInvestorPortalSettings = withErrorHandling(adminApi.updateInvestorPortalSettings);

const SupportInfoEditor = ({ settings: originalSettings }: Props) => {
  const theme = useTheme();
  const { hasPermissions } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [settings, setSettings] = useState<SupportInfoSettings>(originalSettings);
  const [savedSettings, setSavedSettings] = useState<SupportInfoSettings>(originalSettings);
  const [error, setError] = useState<string>();
  const [isSaving, setSaving] = useState(false);

  const handleSaveClick = async () => {
    setSaving(true);

    const [resp, error] = await updateInvestorPortalSettings({
      supportEmail: settings.supportEmail,
      supportPhone: settings.supportPhone,
    });

    setSaving(false);

    if (error) {
      logError(error, "[SupportInfoEditor] updateInvestorPortalSettings");
      sendNotificationError("Failed to save settings");
      return;
    }

    sendNotification("Settings saved successfully");
    setSettings(resp.settings);
    setSavedSettings(resp.settings);
  };

  const handleEmailChange = (value: string, isValid: boolean) => {
    if (isValid) {
      setSettings({ ...settings, supportEmail: value });
      setError("");
    } else {
      setError("Invalid email");
    }
  };

  const handlePhoneChange = (value: string) => {
    setSettings({ ...settings, supportPhone: value });
  };

  const handleResetClick = () => {
    setSettings(savedSettings);
    setError("");
  };

  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);

  const disableSave = !canEditSettings || !!error || deepEqual(savedSettings, settings);

  return (
    <Stack py={2.5} spacing={3}>
      <Stack spacing={2}>
        <Typography color="text.secondary">
          Add Help Center e-mail that will be displayed inside Investor Portal and on Sign-in page.
        </Typography>
        <EmailTextField
          required
          disabled={!canEditSettings}
          fieldName="Email"
          value={settings?.supportEmail}
          onBlur={handleEmailChange}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography color="text.secondary">
          Add Help Center phone number that will be displayed inside Investor Portal. (Optional)
        </Typography>
        <PhoneFieldExt
          name="phone"
          containerClassName="my-profile-phone-number"
          phoneInputProps={{
            preferredCountries: ["us"],
            value: settings.supportPhone,
            placeholder: "",
          }}
          onValidated={(_, value) => setError(value ? "" : "Invalid phone number")}
          onValueChanged={handlePhoneChange}
          disabled={!canEditSettings}
          skipValidation={true}
        />
      </Stack>

      <Stack direction="row" spacing={1}>
        <LoadingButton
          variant="contained"
          sx={{ width: theme.spacing(10) }}
          disabled={disableSave}
          loading={isSaving}
          onClick={handleSaveClick}
        >
          Save
        </LoadingButton>
        <Button disabled={disableSave} variant="text" color="secondary" onClick={handleResetClick}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default SupportInfoEditor;
