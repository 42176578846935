import { TextField } from "@mui/material";
import { FocusEvent, forwardRef, useRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Maybe } from "../../../../../../shared/types";
import { getLocalNumberFormatConfig } from "../../../../../../shared/utilities/formatters";
import { getLowConfidenceStyles } from "../invoice-details/invoiceHelper";
import InvoiceControlWithWarning from "./InvoiceControlWithWarning";

interface Props {
  onChange: (floatValue?: number) => void;
  value?: number;
  placeholder?: string;
  label?: string;
  validationError?: string;
  isLowConfident?: boolean;
  disabled?: boolean;
}

const formattingConfig = getLocalNumberFormatConfig();

const InvoiceCurrency = forwardRef<NumericFormatProps, Props>((props, ref) => {
  const { onChange, value, validationError, isLowConfident, disabled, ...other } = props;

  const currentValueRef = useRef<number | null>();
  currentValueRef.current = value === undefined ? null : value;

  const handleChange = (newValue: Maybe<number>) => {
    onChange(newValue ?? undefined);
  };

  return (
    <InvoiceControlWithWarning validationError={validationError} isLowConfident={isLowConfident}>
      <NumericFormat
        {...other}
        value={currentValueRef.current}
        getInputRef={ref}
        onValueChange={(values) => (currentValueRef.current = values.floatValue)}
        onBlur={() => handleChange(currentValueRef.current)}
        thousandSeparator={formattingConfig.groupSeparator}
        decimalSeparator={formattingConfig.decimalSeparator}
        allowNegative={true}
        decimalScale={2}
        fixedDecimalScale
        customInput={TextField}
        slotProps={{
          input: {
            onFocus: (event: FocusEvent<HTMLInputElement>) => event.target.select(),
          },
        }}
        fullWidth
        error={!!validationError || isLowConfident}
        disabled={disabled}
        sx={!validationError && isLowConfident ? getLowConfidenceStyles() : undefined}
      />
    </InvoiceControlWithWarning>
  );
});

InvoiceCurrency.displayName = "InvoiceCurrency";

export default InvoiceCurrency;
