import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import crmConnectorsApi, { CrmObjectSyncSettings, CrmSyncObjectKey } from "../../../../api/crmConnectorsApi";
import { useCrmConnectorContext } from "../CrmConectorContext";
import CrmObjectMapping from "./CrmObjectMapping";
import FieldMappingDialog from "./FieldMappingDialog";

interface ObjectMappingRowProps {
  objectSyncSettings: CrmObjectSyncSettings;
  onFieldMappingClick: () => void;
}

const ObjectMappingRow = ({ objectSyncSettings, onFieldMappingClick }: ObjectMappingRowProps) => (
  <Stack direction="row" spacing={2} mb={2}>
    <CrmObjectMapping
      objectSyncSettings={objectSyncSettings}
      crmFilterValue={objectSyncSettings.accountTypeFilterValue}
    />
    <Button variant="text" color="secondary" startIcon={<SyncAltIcon />} onClick={onFieldMappingClick}>
      Field Mapping
    </Button>
  </Stack>
);

const ConnectionManagement = () => {
  const { crmApiName, crmName, syncSettings } = useCrmConnectorContext();

  const [ourObjects, fetchOurObjectsError] = useFetch(crmConnectorsApi[crmApiName].getOurObjects);

  const [crmObjects, fetchCrmObjectsError] = useFetch(crmConnectorsApi[crmApiName].getCrmObjects);

  const [editObjectKey, setEditObjectKey] = useState<CrmSyncObjectKey>();

  const fetchError = fetchOurObjectsError || fetchCrmObjectsError;
  if (fetchError) {
    logError(fetchError, "[ConnectionManagement] fetch");
    return <DataLoadingFailed title="Loading connector settings failed" />;
  }

  const loading = ourObjects === undefined || crmObjects === undefined;
  if (loading) {
    return <InlineLoader />;
  }

  const handleFieldMappingClick = (key: CrmSyncObjectKey) => () => setEditObjectKey(key);

  const handleFieldMappingCancel = () => setEditObjectKey(undefined);

  const objectSyncSettings = editObjectKey && syncSettings ? syncSettings[editObjectKey] : undefined;

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Objects synchronization
      </Typography>
      <Typography mb={3} color="text.secondary">
        Specify the data you want to synchronize
      </Typography>
      <Stack direction="row" spacing={36} mt={3} mb={2}>
        <Typography variant="subtitle2">Entrilia</Typography>
        <Typography variant="subtitle2">{crmName}</Typography>
      </Stack>
      {syncSettings && (
        <ObjectMappingRow
          objectSyncSettings={syncSettings.crmContactsSyncSettings}
          onFieldMappingClick={handleFieldMappingClick("crmContactsSyncSettings")}
        />
      )}
      {syncSettings && (
        <ObjectMappingRow
          objectSyncSettings={syncSettings.crmInvestorsSyncSettings}
          onFieldMappingClick={handleFieldMappingClick("crmInvestorsSyncSettings")}
        />
      )}

      {editObjectKey && objectSyncSettings && (
        <FieldMappingDialog
          objectKey={editObjectKey}
          objectSyncSettings={objectSyncSettings}
          onCancel={handleFieldMappingCancel}
          crmObjects={crmObjects}
          ourObjects={ourObjects}
        />
      )}
    </>
  );
};

export default ConnectionManagement;
