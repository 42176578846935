import { Stack } from "@mui/material";
import { useState } from "react";
import { getErrorMessage } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import crmConnectorsApi from "../../../api/crmConnectorsApi";
import { useClientContext } from "../../../context/ClientContext";
import IntAppLogo from "../../../images/intapp.png";
import IntegrationHeader from "../integration/IntegrationHeader";
import CrmActions from "./CrmActions";
import { CrmConnectorContextProvider } from "./CrmConectorContext";
import CrmManagement from "./CrmManagement";
import CrmSetupInfo from "./CrmSetupInfo";
import FullResyncInfo from "./FullResyncInfo";
import TestConnectionInfo from "./TestConnectionInfo";
import {
  FullResyncState,
  getInitialFullResyncState,
  getInitialTestConnectionState,
  TestConnectionState,
} from "./crmConnectorState";

const promotionText =
  "DealCloud is a comprehensive deal and relationship management platform developed by IntApp. It is designed to help financial and professional services firms streamline their deal processes, manage relationships, and make data-driven decisions.";

const DealCloudConnectorPage = () => {
  const { dealCloudApp } = useClientContext();

  const [connectionInfo, fetchError] = useFetch(crmConnectorsApi.dealcloud.getConnection);

  const [testConnectionState, setTestConnectionState] = useState<TestConnectionState>(getInitialTestConnectionState());
  const [fullResyncState, setFullResyncState] = useState<FullResyncState>(getInitialFullResyncState());

  if (fetchError) {
    logError(fetchError, "[DealCloudConnectorPage] getConnection");
    return <DataLoadingFailed title="Loading connector settings failed" text={getErrorMessage(fetchError)} />;
  }

  const loading = connectionInfo === undefined;
  if (loading) {
    return <InlineLoader />;
  }

  return (
    <CrmConnectorContextProvider
      crmName="DealCloud"
      crmApiName="dealcloud"
      logoSrc={IntAppLogo}
      connectionInfo={connectionInfo}
      syncEnabled={false}
      testConnectionState={testConnectionState}
      onTestConnectionStateUpdate={setTestConnectionState}
      fullResyncState={fullResyncState}
      onFullResyncStateUpdate={setFullResyncState}
    >
      <IntegrationHeader title="DealCloud" logoSrc={IntAppLogo} text={promotionText} appStatus={dealCloudApp?.status} />
      <CrmSetupInfo />
      <CrmActions hideTestConnection hideDelete hideDataSyncToggle />
      <Stack spacing={2} mt={3} ml={3} width="48rem">
        <TestConnectionInfo />
        <FullResyncInfo />
      </Stack>
      <CrmManagement hideConnectionManagement excludeLogsColumns={["contacts", "investors"]} />
    </CrmConnectorContextProvider>
  );
};

export default DealCloudConnectorPage;
