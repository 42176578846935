import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { withErrorHandling } from "../../../../api/axiosHelper";
import { MetricExtension } from "../../../../api/dataCollectionTypes";
import { useNotificationContext } from "../../../../contexts/NotificationContext";
import useFetch from "../../../../hooks/useFetch";
import { logError } from "../../../../logging";
import DialogCloseButton from "../../../DialogeCloseButton";
import InlineLoader from "../../../inlineLoader/InlineLoader";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";

interface Props {
  metricId: string;
  metricName: string;
  addedExtensionIds: string[];
  allowAddingExtensionValues: boolean;
  onClose: () => void;
  onAdded: (metricExtension: MetricExtension, isNew: boolean) => void;
}

const AddMetricExtensionDialog = ({
  metricId,
  metricName,
  addedExtensionIds,
  allowAddingExtensionValues,
  onClose,
  onAdded,
}: Props) => {
  const { metricExtensionsService, recipientObjectId } = useDataSubmissionFormContext();
  const { sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [extensionValue, setExtensionValue] = useState("");

  const getExtensions = useCallback(
    () => metricExtensionsService.getMetricExtensions(metricId, recipientObjectId),
    [metricExtensionsService, metricId, recipientObjectId]
  );

  const [existingExtensions, fetchError, { isFetching }] = useFetch(getExtensions);

  const existingExtensionValues = useMemo(
    () => existingExtensions?.filter((ext) => !addedExtensionIds.includes(ext.id))?.map((e) => e.value) ?? [],
    [addedExtensionIds, existingExtensions]
  );

  const handleAddClick = async () => {
    const existingExtension = existingExtensions?.find((e) => e.value === extensionValue);
    if (existingExtension) {
      onAdded(existingExtension, false);
      return;
    }

    if (!allowAddingExtensionValues) {
      sendNotificationError("Selected extension value is not valid");
      return;
    }

    const createExtension = withErrorHandling(metricExtensionsService.createMetricExtension);

    setSaving(true);

    const [newExtension, error] = await createExtension({
      metricId,
      value: extensionValue,
      objectId: recipientObjectId,
    });

    setSaving(false);

    if (error) {
      logError(error, "[AddMetricExtensionDialog] createMetricExtension");
      sendNotificationError("Could not create metric extension");
      return;
    }

    onAdded(newExtension, true);
  };

  const isNewValueValid = extensionValue.trim().length > 0;
  const placeholder = allowAddingExtensionValues ? "Type new name or select from the list" : "Select from the list";

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Extension for {metricName}</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        {fetchError ? (
          <Typography color="error" py={1}>
            Could not load existing metric extensions
          </Typography>
        ) : isFetching || existingExtensions === undefined ? (
          <InlineLoader />
        ) : (
          <Autocomplete
            freeSolo={allowAddingExtensionValues}
            fullWidth
            value={extensionValue}
            options={existingExtensionValues}
            noOptionsText="No existing extensions yet"
            renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
            onInputChange={(_, value) => {
              if (value) {
                setExtensionValue(value);
              }
            }}
          />
        )}
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          disabled={isFetching || !!fetchError || !isNewValueValid}
          onClick={handleAddClick}
          loading={isSaving}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddMetricExtensionDialog;
